<script setup>
import { ref } from "vue";
import {useAuthStore} from "@/store/store";
import apiCaller from "@/apiCalls/apiCaller";
import router from "@/router/router";

const authStore = useAuthStore();
const user = authStore.getUser;

const handleLogout = async () => {
  try {
    const response = await apiCaller.post('logout', {});
    authStore.clearUserAndToken();
    if(response.data.status==true){
      router.push('/login');
    }
  } catch (error) { console.log(error) }
};

const locale = ref(localStorage.getItem('lang') ?? 'en');
function changeLocale(v){
  locale.value = v.key;
  localStorage.setItem('lang', v.key);
}

</script>
<template>
  <a-layout-header style="background: #fff; display: flex; align-items: center; justify-content: end; width: 100%;">

    <!-- Language dropdown -->
    <a-dropdown placement="bottom" class="me-4">
      <a-button  style="display: flex; align-items: center">
        <a-space v-if="locale == 'en'">
          <img src="@/assets/images/usa.jpg" alt="Usa Flag" class="flag-icon" />
          <span class="gray-menu-item">{{ $t('En') }}</span>
        </a-space>
        <a-space v-else>
          <img src="@/assets/images/china.jpg" alt="China Flag" class="flag-icon" />
          <span class="gray-menu-item">{{ $t('Cn') }}</span>
        </a-space>
      </a-button>
      <template #overlay>
        <a-menu style="margin-top: 6px" @click="changeLocale" placement="center">
          <a-menu-item key="en" icon="@/assets/images/china.jpg" @click="$i18n.locale='en'"  class="gray-menu-item">
            <img src="@/assets/images/usa.jpg" alt="English Flag" class="flag-icon" />
            <span class="gray-menu-item">{{ $t('ENGLISH') }}</span>
          </a-menu-item>
          <a-menu-item key="cn" icon="@/assets/images/china.jpg" @click="$i18n.locale='cn'"  class="gray-menu-item">
            <img src="@/assets/images/china.jpg" alt="China Flag" class="flag-icon" />
            <span class="gray-menu-item">{{ $t('CHINA') }}</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <a-dropdown class="admin-dropdown" placement="bottom">
      <template #overlay>
        <a-menu style="margin-top: -8px">
          <a-menu-item key="ac" style="padding-right: 30px; padding-bottom: 10px">
            <a-card hoverable>
              <a-card-meta :title="user.user_name">
                <template #description>{{user.email}}</template>
              </a-card-meta>
            </a-card>
          </a-menu-item>
          <a-divider class="divider" />
          <a-menu-item key="se" style="padding-right: 30px; padding-bottom: 10px">
            <RouterLink to="/setting-page">
              <font-awesome-icon class="gray-menu-item" :icon="['fas', 'gear']" />
              <span style="padding-left: 8px" class="gray-menu-item">{{ $t('Settings') }}</span>
            </RouterLink>
          </a-menu-item>
          <a-divider class="divider" />
          <a-menu-item key="lg" style="padding-right: 30px; padding-bottom: 10px" @click="handleLogout">
            <font-awesome-icon class="gray-menu-item" :icon="['fas', 'right-from-bracket']" />
            <span style="padding-left: 8px" class="gray-menu-item">{{ $t('Log out') }}</span>
          </a-menu-item>
        </a-menu>
      </template>

      <div>
        <img :src="user.image_url" alt="Avatar" class="avatar" />
        <!-- <a-avatar focus :size="44"> </a-avatar> -->
      </div>
    </a-dropdown>
  </a-layout-header>
</template>
<style scoped>
.flag-icon {
  width: 24px;
  padding-right: 3px;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

.ant-btn-default {
  border: 0px;
}

.ant-btn-default:hover {
  color: black;
}

.ant-divider-horizontal {
  margin: 5px 0px;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>