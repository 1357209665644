<template>


  <el-dialog
      v-model="showModalVisible"
      width="60%"
      :before-close="handleReset"
  >
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <h5>{{props.inquiry.title}}</h5>
        </div>
      </template>

      <div>
        <a-row>
          <a-col>
            <a-row>
              <a-col><h5>{{ $t('Author') }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.inquiry.author}}</a-col>
            </a-row>

          </a-col>
          <a-col :style="{marginLeft: '100px'}">
            <a-row>
              <a-col><h5>{{ $t('Email') }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.inquiry.author_email}}</a-col>
            </a-row>
          </a-col>

          <a-col :style="{marginLeft: '100px'}">
            <a-row>
              <a-col><h5>{{ $t('Date') }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}" >{{props.inquiry.created_at}}</a-col>

            </a-row>


          </a-col>

        </a-row>


      </div>

      <template #footer>
        <h4>{{props.inquiry.title}}</h4>

        <div class="content-container">
          <div v-html="props.inquiry.content"></div>

        </div>

      </template>



    </el-card>
    <div>

    </div>
  </el-dialog>

</template>

<style scoped>
.content-container {
  text-align: left;
}

.left-align {
  text-align: left;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}
</style>

<script setup>

import {ref, onMounted, defineEmits, defineProps} from 'vue';

const props = defineProps({
  inquiry: Object,
  contentColumn: String
});

let showModalVisible = ref(false);
const emits = defineEmits(['cancel:viewModal', 'handleEdit'])


onMounted(async ()=>{
  showModalVisible.value = true;
});

const handleReset = () => {

  showModalVisible.value = false;

  emits('cancel:showViewModal');
};


</script>