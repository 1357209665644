<template>

  <el-dialog
      title="Create Menu"
      v-model="modalVisible"
      width="60%"
      :before-close="handleReset"
      style="max-height:400px; overflow:auto; scrollbar-width:thin;"
  >
    <el-form :model="formData" :rules="formRules" ref="formRef" label-position="left" label-width="50px">

      <el-form-item label="Title:" prop="title">
        <el-input type="text"
                  v-model="formData.title" placeholder="Title" aria-required="true">
        </el-input>
      </el-form-item>
      <el-form-item label="Url:" prop="url">
        <el-input type="text"
            v-model="formData.url" placeholder="Url" aria-required="true">
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleReset">Cancel</el-button>
        <el-button type="primary" @click="handleSubmit()" :disabled="isSubmit">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script setup>

import {ref, defineEmits, onMounted, defineProps} from 'vue';
import apiCaller from "@/apiCalls/apiCaller";

const formRef = ref(null);
let isSubmit = ref(false);
const props = defineProps({
  parent_id: Number,
  menu_type_id: Number
});

let modalVisible = ref();

const formData = ref({
  title: '',
  url: '',
  parent_id: '',
  menu_type_id: '',
});


const formRules = ref({
  title: [
    { required: true, message: 'Please enter the title', trigger: 'blur' }
  ],
  url: [
    { required: true, message: 'Please enter the url', trigger: 'blur' }
  ],
});


const emits = defineEmits(['cancel:showModal', 'saved:showModal'])

onMounted(() => {
  modalVisible.value = true;
  formData.value.parent_id = props.parent_id;
  formData.value.menu_type_id = props.menu_type_id;
});

const handleSubmit = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      isSubmit.value = true;

      const data = new FormData();
      data.append('title', formData.value.title);
      data.append('url', formData.value.url);
      if(formData.value.parent_id!==''){
        data.append('parent_id', formData.value.parent_id);
      }
      data.append('menu_type_id', formData.value.menu_type_id);

      apiCaller.post('menus', data).then((response) => {
        emits('saved:showModal', response.data.data);
        isSubmit.value = false;
      }).catch(() => {
        isSubmit.value = false;
      });
    }
  })
}

const handleReset = () => {
  modalVisible.value = false;
  emits('cancel:showModal');
};

</script>