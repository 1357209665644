<template>

  <LayoutComponent>
    <h4 class="m-4 mb-md-3" style="text-align: left;">{{ $t(capitalizeFirstLetter(props.role+'s')) }}</h4>

    <div class="page-wrapper">

      <div class="page-head">

        <div class="search-container">
          <el-input
              type="text"
              style="width: 300px;"
              :placeholder="$t('search')"
              v-model="params.search"
          >
            <template #append>
              <el-button :icon="Search" @click="handleFilter"/>
            </template>
          </el-input>
        </div>
        <el-button type="primary" @click="openCreateModal" :icon="Plus">{{ $t('New') }}</el-button>

      </div>

      <div class="page-content-wrapper">
        <div v-if="listDataLoading">
            <a-skeleton active :paragraph="{ rows: 9 }"/>
        </div>
        <div v-else>
          <a-table
              :columns="columns"
              :data-source="data"
              :pagination="{ current: params.currentPage, pageSize: 10, total: totalRecords }"
              :style="{ width: '100%', overflowX: 'auto' }"
              :scroll="{x: true}"
              @change="handlePaginationChange"

          >

            <template #headerCell="{ column }">
                <span v-if="column.dataIndex !=='featured'">{{ $t(`${column.title}`) }}</span>
            </template>

            <template #bodyCell="{ column, record, text }">
              <template v-if="column.dataIndex === 'featured'">
                <div style="margin-top: -10px">
                  <label>
                    <input type="checkbox" class="star-checkbox" @click="toggleFeatured(record.id)" :checked="text===1"/>
                    <div class="star-label" :checked="text===1"></div>
                  </label>
                </div>
              </template>
            </template>
            <template #image_url="{ text: image_url }">
                        <span>
                          <img :src="image_url" alt="cover" style="width: 25px; height: 25px; object-fit: cover;"/>
                        </span>
            </template>

            <template #actions="{ record }">
              <el-button type="primary" plain :icon="View" @click="handleShow(record)"/>
            </template>

          </a-table>
        </div>
      </div>
      <UserCreator v-if="showModal" :role="props.role" @cancel:showModal="hideModal" @saved:showModal="refresh"/>
      <UserViewer v-if="showViewModal" :user="content" @cancel:showViewModal="hideViewModal" @handleEdit="handleEdit" @handleDelete="handleDelete"/>
      <UserEditor v-if="showEditModal" :user="content" :role="props.role" @cancel:showEditModal="hideEditModal" @saved:showEditModal="refreshEdit"/>

    </div>

  </LayoutComponent>

</template>

<style scoped>
.page-wrapper {
  background: #fff;
  margin-top: 16px;
}

.page-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid #ecf3fa;
}

.page-title {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
}

.page-content-wrapper {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.search-container {
  position: relative;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
  box-sizing: border-box;
}

.search-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: #0c85d0;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

</style>

<script setup>

import {onMounted, ref, defineProps} from "vue";
import LayoutComponent from "@/components/layout/LayoutComponent.vue";
import apiCaller from "@/apiCalls/apiCaller";
import UserCreator from "@/components/common/creators/UserCreator.vue";
import {
  View,
  Plus,
  Search
} from '@element-plus/icons-vue'
import Swal from "sweetalert2";
import UserViewer from "@/components/common/views/UserViewer.vue";
import UserEditor from "@/components/common/editors/UserEditor.vue";
import {ElLoading} from "element-plus";

let showModal = ref(false);
let showViewModal = ref(false);
let showEditModal = ref(false);
let listDataLoading = ref(false);

const props = defineProps({
  role: String
});

const type = ref({
  type: 'users'
});


const columns = [
  {
    title: "⭐",
    dataIndex: "featured",
    width: 10,

  },

  {
    title: 'Cover',
    dataIndex: 'image_url',
    key: 'image_url',
    slots: {customRender: 'image_url'},

  },
  {
    title: 'Name',
    dataIndex: 'name',

  },
  {
    title: 'Email',
    dataIndex: 'email',

  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',


  },

  {
    title: 'Actions',
    dataIndex: 'actions',
    slots: {customRender: 'actions'},

  },
];

let data = ref([]);
let content = ref({});

let params = ref({
  role: props.role,
  currentPage: 1,
  search: ''
});

let totalRecords = ref(10);

async function fetchData() {
  listDataLoading.value = true;
  data.value = [];
  const res = await apiCaller.get('users', params.value);

  data.value = res.data.data.data;
  totalRecords.value = res.data.data.total;
  listDataLoading.value = false;
}

const toggleFeatured = async (id) => {

  const loadingInstance = ElLoading.service({ fullscreen: true });
  await apiCaller.post('feature/'+id, type.value);
  fetchData();
  loadingInstance.close();
}


onMounted(async () => {

  if(props.role === 'admin') {
    document.title = 'Admins';
  } else {
    document.title = 'Users'
  }

  params.value.role = props.role;

  fetchData();
});

async function handlePaginationChange(pagination) {
  params.value.currentPage = pagination.current;

  fetchData();
}

function handleFilter() {
  fetchData();
}

function openCreateModal() {
  showModal.value = true;
}

function hideModal() {
  showModal.value = false;
}

function refresh() {
  fetchData();
  hideModal();
}

const handleShow = record => {
  content.value = record;

  showViewModal.value = true;
};

const handleDelete = async (record) => {
  showViewModal.value = false;

  const result = await Swal.fire({
    icon: 'warning',
    title: 'Are you sure?',
    text: "This action can't be reverted",
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
  });

  if (result.isConfirmed) {
    try {
      const url = 'users/' + record.id;

      await apiCaller.delete(url);

      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Your record has been deleted.',
      });

      fetchData();

    } catch (error) {

      console.error('Error deleting record:', error);


      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to delete the record. Please try again.',
      });
    }
  }
}

function hideViewModal() {
  showViewModal.value = false;
}

function handleEdit() {
  hideViewModal();
  showEditModal.value = true;
}

function hideEditModal() {
  showEditModal.value = false;
}

function refreshEdit() {
  fetchData();
  showEditModal.value = false;
}

function capitalizeFirstLetter(type)
{
  return type.charAt(0).toUpperCase() + type.slice(1);
}


</script>