import ImageUploader from "quill-image-uploader";
import BlotFormatter from "quill-blot-formatter";
import apiCaller from "@/apiCalls/apiCaller";

export const quillModules = [
    {
        name: 'imageUploader',
        module: ImageUploader,
        options: {
            upload: file => {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    data.append("file", file);

                    apiCaller.post('image', data)
                        .then(res => {
                            console.log(res.data.data.url);
                            resolve(res.data.data.url);
                        })
                        .catch(err => {
                            reject("Upload failed");
                            console.error("Error:", err)
                        })
                })
            },

        },

    },

    {
        name: 'blotFormatter',
        module: BlotFormatter,
        options: {}
    },

];

