<template>

  <el-dialog
      title="Create Menu"
      v-model="modalVisible"
      width="60%"
      :before-close="handleReset"
      style="max-height:400px; overflow:auto; scrollbar-width:thin;"
  >
    <el-form :model="formData" :rules="formRules" label-position="left">

      <el-form-item label="Title:" prop="title" :error="getError('title')">
        <el-input type="text"
            v-model="formData.title" placeholder="Title" aria-required="true">
        </el-input>

      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleReset">Cancel</el-button>
        <el-button type="primary" @click="handleSubmit()" :disabled="isSubmit">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue';
import apiCaller from "@/apiCalls/apiCaller";

let isSubmit = ref(false);
let formErrors = ref({});
let modalVisible = ref();
const emits = defineEmits(['cancel:showModal', 'saved:showModal'])

const formData = ref({
  title: '',
});

const formRules = ref({
  title: [
    { required: true, message: 'Please enter the title', trigger: 'blur' }
  ],
});

onMounted(() => {
  modalVisible.value = true;
});

const handleSubmit = () => {
    isSubmit.value = true;

    const data = new FormData();
    data.append('title', formData.value.title);

    apiCaller.post('menu-types', data).then((response) => {
        emits('saved:showModal', response.data.data);
        isSubmit.value = false;
    }).catch(error => {
        formErrors.value = error.response.data.data;
        isSubmit.value = false;
    });
}

const handleReset = () => {
  modalVisible.value = false;
  emits('cancel:showModal');
};

function getError(key)
{
  if(formErrors.value[key])
  {
    return formErrors.value[key][0];
  }
}

</script>