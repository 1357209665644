<template>

  <LayoutComponent>
    <h4 class="m-4 mb-md-0" style="text-align: left;">{{ $t('Welcome to Dashboard') }}</h4>
    <a-row class="m-4">
      <a-col :span="4">
        <a-card style="width: 150px">
          <h6 class="text-uppercase">{{ $t('Pages') }}</h6>
          <h3>{{dashBoardData['pages']}}</h3>
        </a-card>

      </a-col>
      <a-col :span="4">
        <a-card style="width: 150px">
          <h6 class="text-uppercase">{{ $t('Products') }}</h6>
          <h3>{{dashBoardData['products']}}</h3>
        </a-card>

      </a-col>
      <a-col :span="4">
        <a-card style="width: 150px">
          <h6 class="text-uppercase">{{ $t('Posts') }}</h6>
          <h3>{{dashBoardData['posts']}}</h3>
        </a-card>

      </a-col>
      <a-col :span="4">
        <a-card style="width: 150px">
          <h6 class="text-uppercase">{{ $t('Cases') }}</h6>
          <h3>{{dashBoardData['cases']}}</h3>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card style="width: 150px">
          <h6 class="text-uppercase">{{ $t('Jobs') }}</h6>
          <h3>{{dashBoardData['jobs']}}</h3>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card style="width: 150px">
          <h6 class="text-uppercase">{{ $t('Users') }}</h6>
          <h3>{{dashBoardData['users']}}</h3>
        </a-card>
      </a-col>

    </a-row>

    <a-row class="m-4">
      <a-col :span="4">
        <a-card style="width: 150px">
          <h6 class="text-uppercase">{{ $t('Reviews') }}</h6>
          <h3>{{dashBoardData['reviews']}}</h3>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card style="width: 150px">
          <h6 class="text-uppercase">{{ $t('Inquiries') }}</h6>
          <h3>{{dashBoardData['inquiries']}}</h3>
        </a-card>
      </a-col>

    </a-row>

  </LayoutComponent>

</template>

<script setup>

import apiCaller from "@/apiCalls/apiCaller";
import LayoutComponent from "@/components/layout/LayoutComponent.vue";
import {onMounted} from "vue";
import {ref} from "vue";

let dashBoardData = ref({
  pages: 0,
  products: 0,
  posts: 0,
  cases: 0,
  jobs: 0,
  users: 0,
  reviews: 0,
  inquiries: 0
});
const params = {};

onMounted(async () =>{
  document.title = 'Dashboard';
  await apiCaller.get('dashboard', params)
    .then(response => {
      dashBoardData.value = response.data.data;
    }).catch(error => {
        console.log(error);
    });

});



</script>