<template>
  <LayoutComponent>

    <div class="page-wrapper">

      <div class="page-head">
        <h4 class="mb-md-0" style="text-align: left;">Menu List</h4>
        
      </div>

      <div class="page-content-wrapper">

        <div style="border:1px solid #f2f4f4; padding:10px 10px; margin-bottom:20px;">
          <div style="display:flex; flex-wrap:wrap; align-items:center">
            <span style="margin-right:5px">Select a menu to edit:</span>
            <a-select
              ref="select"
              v-model:value="menuTypeId"
              style="min-width:250px; max-width:400px"
              @change="handleChange"
            >
              <a-select-option v-for="data in menuTypeList" :key="data.id" :value="data.id">{{ data.title }}</a-select-option>
            </a-select>
            <span style="padding:0px 5px">or</span>
            <span @click="openMenuTypeModal()" style="color: Teal; cursor:pointer;">Create a new menu</span>
          </div>
        </div>

        <div>
          <p style="font-weight:bold; font-size:16px; text-align:left; margin-bottom:5px">Menu Structure</p>
          
          <div style="border:1px solid #f2f4f4; margin-bottom:15px; min-height:120px">
            <div style="display:flex; align-items:center; padding:10px; border-bottom:1px solid #f2f4f4; background-color: #f8f9f9;">
              <span style="margin-right:5px">Menu Name</span>
              <a-input v-model:value="selectedMenuType.title" type="text" style="width:220px;"/>
            </div>

            <div style="padding:10px; position:relative">
              <div style="position:absolute; right:11px">
                <el-button
                    type="primary"
                    @click="openCreateModal('')"
                    :icon="Plus"
                    :disabled="selectedMenuType.id==undefined"
                >
                  New
                </el-button>
              </div>

              <div @dragover.prevent @drop="dropOnSection()">
                <div @dragover.prevent @drop="dropOnTop('top')" style="height:39px; width:340px">
                  <!-- drop on top -->
                </div>
                  <div v-for="menu in menus" :key="menu.id" class="menu-section">
                    <div v-if="menu.parent_id==null">
                        <div
                          @dragover.prevent 
                          :draggable="isDraggable" 
                          @dragstart="dragStart(menu)" 
                          @drop="dropOnMainMenu(menu)" 
                          :class="['menu-style', { 'draging-main': menu.id == dragingMenuId },]"
                          style="position:relative"
                        >
                          <el-dropdown style="position:absolute; right:5px">
                              <span class="el-dropdown-link">
                                  <VerticalDots />
                              </span>
                              <template #dropdown>
                                  <el-dropdown-menu>
                                    <el-dropdown-item @click.prevent="openCreateModal(menu.id)" style="font-size:12px">Add Submenu</el-dropdown-item>
                                    <el-dropdown-item @click.prevent="editMenu(menu)" style="font-size:12px">Edit</el-dropdown-item>
                                    <el-dropdown-item @click.prevent="deleteMenu(menu.id)" style="font-size:12px">Delete</el-dropdown-item>
                                </el-dropdown-menu>
                              </template>
                          </el-dropdown>
                          {{ menu.title.length > 43 ? menu.title.slice(0,43)+'...' : menu.title }}
                        </div>
                        <div v-for="sub1 in menu.children" :key="sub1.id" style="margin-left:30px; margin-top:7px">
                            <div 
                              @dragover.prevent 
                              :draggable="isDraggable" 
                              @dragstart="dragStart(sub1)" 
                              @drop="dropOn2ndLayer(sub1)" 
                              :class="['menu-style', { 'draging-main': sub1.id == dragingMenuId },]"
                              style="position:relative"
                            >
                              <el-dropdown style="position:absolute; right:5px">
                                <span class="el-dropdown-link">
                                    <VerticalDots />
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                      <el-dropdown-item @click.prevent="openCreateModal(sub1.id)" style="font-size:12px">Add Submenu</el-dropdown-item>
                                      <el-dropdown-item @click.prevent="editMenu(sub1)" style="font-size:12px">Edit</el-dropdown-item>
                                      <el-dropdown-item @click.prevent="deleteMenu(sub1.id)" style="font-size:12px">Delete</el-dropdown-item>
                                  </el-dropdown-menu>
                                </template>
                              </el-dropdown>
                              {{ sub1.title.length > 43 ? sub1.title.slice(0,43)+'...' : sub1.title }}
                            </div>
                            <div v-for="sub2 in sub1.children" :key="sub2.id" style="margin-left:30px; margin-top:7px">
                                  <div 
                                    @dragover.prevent 
                                    :draggable="isDraggable" 
                                    @dragstart="dragStart(sub2)" 
                                    @drop="dropOn3rdLayer(sub2)" 
                                    :class="['menu-style', { 'draging-main': sub2.id == dragingMenuId },]"
                                    style="position:relative"
                                  >
                                    <el-dropdown style="position:absolute; right:5px">
                                      <span class="el-dropdown-link">
                                          <VerticalDots />
                                      </span>
                                      <template #dropdown>
                                        <el-dropdown-menu>
                                          <el-dropdown-item @click.prevent="editMenu(sub2)" style="font-size:12px">Edit</el-dropdown-item>
                                          <el-dropdown-item @click.prevent="deleteMenu(sub2.id)" style="font-size:12px">Delete</el-dropdown-item>
                                        </el-dropdown-menu>
                                      </template>
                                    </el-dropdown>
                                    {{ sub2.title.length > 40 ? sub2.title.slice(0,40)+'...' : sub2.title }}
                                  </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div @dragover.prevent @drop="dropOnBottom('bottom')" style="height:39px; width:340px">
                    <!-- drop on bottom -->
                  </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      <MenuCreator
          v-if="showCreateModal"
          :parent_id="parentId"
          :menu_type_id="menuTypeId"
          @saved:showModal="closeCreateModalAndFetchData"
          @cancel:showModal="closeCreateModal"
      >
      </MenuCreator>

      <MenuTypeCreator
        v-if="showMenuTypeModal"
        @saved:showModal="closeMenuTypeModalAndFetchData"
        @cancel:showModal="closeMenuTypeModal"
      ></MenuTypeCreator>



      <!-- edit modal  -->
      <el-dialog
        title="Edit Manu"
        v-model="editModalVisible"
        width="60%"
        :before-close="handleReset"
        style="max-height:400px; overflow:auto; scrollbar-width:thin;"
      >
        <el-form :model="editFormData" :rules="editFormRules" label-position="left" ref="editFormRef" label-width="50px">
          <el-form-item label="Title:">
            <el-input type="text" v-model="editFormData.title" placeholder="Title" aria-required="true"></el-input>
          </el-form-item>
          <el-form-item label="Url:" prop="url">
            <el-input type="text" v-model="editFormData.url" placeholder="Url" aria-required="true"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleReset">Cancel</el-button>
            <el-button type="primary" @click="handleEditFormSubmit()">
              Confirm
            </el-button>
          </span>
        </template>
      </el-dialog>

    </div>
  
  </LayoutComponent>
</template>

<script setup>
import LayoutComponent from "@/components/layout/LayoutComponent.vue";
import {onMounted, ref, watch} from 'vue';
import apiCaller from "@/apiCalls/apiCaller";
import {Plus} from "@element-plus/icons-vue";
import MenuCreator from "@/components/common/creators/MenuCreator.vue";
import MenuTypeCreator from "@/components/common/creators/MenuTypeCreator.vue";
import VerticalDots from "@/components/VerticalDots.vue";

const menus = ref([]);
const parentId = ref('');
const menuTypeId = ref('');
const showCreateModal = ref(false);
const showMenuTypeModal = ref(false);
const editModalVisible = ref(false);
const selectedMenuType = ref({});
const menuTypeList=ref([])
const menuTypeTmpList=ref([])
const counter=ref(0)
const editFormRef = ref(null);
const isDraggable = ref(true);
const dragingMenuId = ref('');

const editFormData = ref({
  id:'',
  title:'',
  parent_id:'',
  url:'',
  position:'',
})

const editFormRules = ref({
  title: [
    { required: true, message: 'Please enter the title', trigger: 'blur' }
  ],
});

const dropOnSection=()=>{
  dragingMenuId.value = '';
}

const getParentPosition=(id)=>{
  let result = 0;
  for(let i=0; i<menus.value.length; i++){
      if(id == menus.value[i].id){
        result = menus.value[i].position;
      }
  }
  return result;
}

const dropOnTop=async(data)=>{
  const draggedItem = JSON.parse(event.dataTransfer.getData("item"));

  let menuPosition = draggedItem.position;

  if(draggedItem.parent_id!==null){
    for(let i=0; i<menus.value.length; i++){
      if(draggedItem.parent_id == menus.value[i].id){
        menuPosition = menus.value[i].position;
      } else {
        for(let j=0; j<menus.value[i].children.length; j++){
          if(draggedItem.parent_id == menus.value[i].children[j].id){
            menuPosition = getParentPosition(menus.value[i].children[j].parent_id);
          }
        }
      }
    }
  }
  
  if(data=='top'){
      const formData = new FormData();
      formData.append('id', draggedItem.id);
      formData.append('menu_type_id', selectedMenuType.value.id);
      formData.append('new_position', 0);
      formData.append('old_position', menuPosition);
      formData.append('_method', 'PATCH');

      await apiCaller.post('menus-position', formData).then(() => {
        fetchData()
      }).catch(() => {});
  }

  dragingMenuId.value = '';
}

const dropOnBottom=async(target)=>{
  const draggedItem = JSON.parse(event.dataTransfer.getData("item"));

  let menuPosition = draggedItem.position;

  if(draggedItem.parent_id!==null){
    for(let i=0; i<menus.value.length; i++){
      if(draggedItem.parent_id == menus.value[i].id){
        menuPosition = menus.value[i].position;
      } else {
        for(let j=0; j<menus.value[i].children.length; j++){
          if(draggedItem.parent_id == menus.value[i].children[j].id){
            menuPosition = getParentPosition(menus.value[i].children[j].parent_id);
          }
        }
      }
    }
  }

  if(target=='bottom'){
    const formData = new FormData();
    formData.append('id', draggedItem.id);
    formData.append('menu_type_id', selectedMenuType.value.id);
    formData.append('new_position', 99);
    formData.append('old_position', menuPosition);
    formData.append('_method', 'PATCH');

    await apiCaller.post('menus-position', formData).then(() => {
      fetchData()
    }).catch(() => {});
  }

  dragingMenuId.value = '';
}

// const dragStart=(item)=>{
//   event.dataTransfer.setData("item", JSON.stringify(item));
//   dragingMenuId.value = item.id;
// }

const dragStart=(item)=> {
   event.dataTransfer.setData("item", JSON.stringify(item));
   dragingMenuId.value = item.id;
}

const dropOnMainMenu=async(target)=> {
  const draggedItem = JSON.parse(event.dataTransfer.getData("item"));
  let dragItem3rdChild=0;

  for(let i=0; i<draggedItem.children.length; i++){
      if(draggedItem.children[i].children.length>0){
        dragItem3rdChild++;
      }
  }

  if(draggedItem.id !== target.id && dragItem3rdChild == 0){
    const formData = new FormData();
    formData.append('id', draggedItem.id);
    formData.append('old_position', draggedItem.position);

    if(target.children.length>0){
      formData.append('new_position', target.children.length);
    } else {
      formData.append('new_position', 0);
    }

    formData.append('new_parent_id', target.id);
    if(draggedItem.parent_id !== null){
      formData.append('old_parent_id', draggedItem.parent_id);
    }
    formData.append('_method', 'PATCH');


    await apiCaller.post('menus', formData).then(() => {
      fetchData()
    }).catch(() => {});
  }

  dragingMenuId.value = '';
}

const dropOn2ndLayer=async(target)=>{
    const draggedItem = JSON.parse(event.dataTransfer.getData("item"));

    // draggedItem.children not allow. as we implement 3 layer menu
    if(draggedItem.children.length==0 && draggedItem.parent_id !== target.id && draggedItem.id !== target.id ) { 
      const formData = new FormData();
      formData.append('id', draggedItem.id);
      formData.append('old_position', draggedItem.position);

      if(target.children.length>0){
        formData.append('new_position', target.children.length);
      } else {
        formData.append('new_position', 0);
      }

      if(draggedItem.parent_id !== null){
        formData.append('old_parent_id', draggedItem.parent_id);
      }
      formData.append('new_parent_id', target.id);
      formData.append('_method', 'PATCH');

      await apiCaller.post('menus', formData).then(() => {
        fetchData()
      }).catch(() => {});

    }
    dragingMenuId.value = '';
}

const dropOn3rdLayer=async(target)=>{
  const draggedItem = JSON.parse(event.dataTransfer.getData("item"));

  if(draggedItem.parent_id == target.parent_id){
    const formData = new FormData();
    formData.append('id', draggedItem.id);
    formData.append('old_position', draggedItem.position);
    formData.append('new_position', target.position);
    formData.append('old_parent_id', draggedItem.parent_id);
    formData.append('new_parent_id', target.parent_id);
    formData.append('_method', 'PATCH');
  
    await apiCaller.post('menus', formData);
  
    fetchData()
  }
  dragingMenuId.value = '';
}

const deleteMenu=async(menuId)=>{
  await apiCaller.delete('menus/'+menuId).then(() => {
    fetchData();
  }).catch(() => {});
}

const editMenu=(menu)=>{
  editModalVisible.value = true;
  editFormData.value.id = menu.id;
  editFormData.value.title = menu.title;
  editFormData.value.parent_id = menu.parent_id;
  editFormData.value.url = menu.url;
  editFormData.value.position = menu.position;
}

const handleReset = () => {
  editModalVisible.value = false;
};

const handleEditFormSubmit=()=>{
  editFormRef.value.validate((valid) => {
    if (valid) {
      const formData = new FormData();
      formData.append('id', editFormData.value.id);
      formData.append('title', editFormData.value.title);

      if(editFormData.value.parent_id !== null){
        formData.append('parent_id', editFormData.value.parent_id);
      }
      formData.append('url', editFormData.value.url);
      formData.append('position', editFormData.value.position);
      formData.append('menu_type_id', selectedMenuType.value.id);
      formData.append('_method', 'PUT');

      apiCaller.post('menus/'+editFormData.value.id, formData).then(() => {
        editModalVisible.value = false;
        fetchData();
      }).catch(() => {});
    }
  });
}

const fetchData = async () => {
  await apiCaller.get('menu-types/'+selectedMenuType.value.id+'/menu').then(response => {
    menus.value = response.data.data;
    isDraggable.value = true;
  }).catch(() => {});
}

const openCreateModal = (id) => {
  parentId.value = id;
  showCreateModal.value = true;
}

const closeCreateModal = () => {
  showCreateModal.value = false;
}

const closeCreateModalAndFetchData = () => {
  closeCreateModal();
  fetchData();
}

const openMenuTypeModal=()=>{
  showMenuTypeModal.value = true;
}

const closeMenuTypeModal=()=> {
  showMenuTypeModal.value = false;
}

const closeMenuTypeModalAndFetchData=()=>{
  closeMenuTypeModal();
  fetchMenuType();
}

const fetchMenuType=async()=>{
  await apiCaller.get('menu-types').then(response => {
    menuTypeList.value = response.data.data;
    menuTypeTmpList.value = JSON.parse(JSON.stringify(response.data.data));

    // menuTypeId.value = menuTypeTmpList.value[0].id;
    // selectedMenuType.value = menuTypeTmpList.value[0];
    // counter.value = 0;
  }).catch(error => {
    console.log(error);
  });
}

const handleChange = (value) => {
  let data = menuTypeTmpList.value.filter(item=>item.id==value);
  selectedMenuType.value = data[0];
  counter.value = 0;
  fetchData();
};

const updateMenuGroup=async()=>{
  if(counter.value!==0){
    const data = new FormData();
    data.append('title', selectedMenuType.value.title);
    data.append('_method', 'PUT');
  
    apiCaller.post('menu-types/'+selectedMenuType.value.id, data).then(() => {
      fetchMenuType();
      counter.value++;
    }).catch(() => {});
  }
  counter.value++;
}

const debounce = (func, delay) => {
	let timeoutId;
	return (...args) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
};



watch(
	() => selectedMenuType.value.title,
  debounce(() => {
		updateMenuGroup();
	}, 500)
);

onMounted(async () => {
  document.title = 'Menu';
  // fetchData();
  fetchMenuType();
});
</script>

<style scoped>

.more-btn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.page-wrapper {
  background: #fff;
  margin-top: 16px;
  overflow: hidden;
}

.page-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid #ecf3fa;
}

.page-title {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
}

.page-content-wrapper {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.menu-section {
  margin-bottom:7px;
  border-radius: 5px;
}
.menu-style {
  width: 340px; 
  background-color: #ebedef; 
  padding:8px; 
  cursor:pointer;
  border: 1px solid #d6dbdf;
  text-align: left;
  font-weight: 600;
  text-transform: capitalize;
}
.action-items {
  color: red;
  font-size: 10px;
}
.draging-main {
  background-color: white ;
  border: 1px dashed #566573 ;
}
</style>