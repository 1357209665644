<template>

  <el-dialog
      v-model="showModalVisible"
      :title="props.video.title"
      width="60%"
      :before-close="handleReset"
  >
    
    <div style="max-height: 40vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">
      <div>
        <a-row>
          <a-col :span="12" style="margin:auto;">
            <div class="video-section">
                <video width="auto" height="250" controls autoplay muted name="media">
                  <source :src="props.video.video_url" type="video/mp4">
                </video>
            </div>
          </a-col>
        </a-row>
      </div>

      <div style="padding: 10px;">
        <a-row>
          <a-col>
            <a-row>
              <a-col><h5>{{ $t('Date') }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.video.created_at}}</a-col>
            </a-row>

          </a-col>
          <a-col :style="{marginLeft: '100px'}">
            <a-row>
              <a-col><h5>{{ $t('Category') }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.video.category_name}}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>

    <template #footer>
      <div style="display:flex; justify-content:space-between; align-items:center">
        <el-button type="danger" plain :icon="Delete" @click="handleDelete(props.video)"/>
        <el-button type="primary" plain :icon="EditPen" @click="handleEdit(record)"/>
      </div>
    </template>
  </el-dialog>

</template>

<style scoped>
.content-container {
  text-align: left;
}

.left-align {
  text-align: left;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}

.video-section {
  height: 400px;
  margin:auto;
}
</style>

<script setup>
import {ref, onMounted, defineEmits, defineProps} from 'vue';
import { EditPen, Delete } from '@element-plus/icons-vue'

const props = defineProps({
  video: Object,
});

let showModalVisible = ref(false);
const emits = defineEmits(['cancel:viewModal', 'handleEdit', 'handleDelete'])

onMounted(async ()=>{
  showModalVisible.value = true;
});

const handleReset = () => {
  showModalVisible.value = false;
  emits('cancel:showViewModal');
};

const handleEdit = () => {
  showModalVisible.value = false;
  emits('handleEdit');
}

const handleDelete = (record) =>{
  emits('handleDelete', record);
}
</script>