import axios from 'axios';
import {useAuthStore} from "@/store/store";
import router from "@/router/router";

const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_API_URL,

});

async function logout() {
    try {
        useAuthStore().clearUserAndToken();
        router.push('/login');
    } catch (logoutError) {
        console.error('Error during logout:', logoutError);
    }
}

const apiCaller = {
    post: async (url, data) => {
        try {

            const response = await instance.post(url, data, {
                headers: {
                    Authorization: `Bearer ${useAuthStore().getToken}`,
                },
            });
            return response;
        } catch (error) {
            if (error.response.status === 401 && url!=='login') {
                logout();
            } else {
                console.log(error);
                throw error;
            }
        }
    },

    get: async (url, params) => {
        try {
            const response = await instance.get(url, {
                headers: {
                    Authorization: `Bearer ${useAuthStore().getToken}`,
                },
                params: params
            });
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                logout();
            } else {
                console.log(error);
                throw error;
            }
        }
    },

    put: async (url, data) => {
        try {
            console.log(data);

            const response = await instance.put(url, data, {
                headers: {
                    Authorization: `Bearer ${useAuthStore().getToken}`,
                },
            });
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                logout();
            } else {
                console.error(error);
                throw error;
            }
        }
    },

    delete: async (url) => {
        try {
            const response = await instance.delete(url, {
                headers: {
                    Authorization: `Bearer ${useAuthStore().getToken}`,
                }
            });
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                logout();
            } else {
                console.error('Error deleting data:', error);
                throw error;
            }
        }
    },
};

export default apiCaller;