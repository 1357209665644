<template>

  <el-dialog
      v-model="showModalVisible"
      :title="props.user.user_name"
      width="60%"
      :before-close="handleReset"
  >
    
      <div style="max-height: 40vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">

        <a-row>
          <a-col :span="12">
            <img :src="props.user.image_url" style="
            width: 80%;
            height: 80%;
            display: block;
             margin: auto;">

          </a-col>
          <a-col>
            <a-row>
              <h2>{{props.user.name}}</h2>
            </a-row>

            <a-row>
              <a-col><b>{{ $t('User Name') }} </b></a-col>
              <a-col :style="{marginLeft: '3px'}">:</a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.user.user_name}}</a-col>
            </a-row>

            <a-row>
              <a-col><b>{{ $t('Email') }} </b></a-col>
              <a-col :style="{marginLeft: '3px'}">:</a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.user.email}}</a-col>
            </a-row>

            <a-row>
              <a-col><b>{{ $t('Mobile') }} </b></a-col>
              <a-col :style="{marginLeft: '3px'}">:</a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.user.mobile}}</a-col>
            </a-row>

            <a-row>
              <a-col><b>{{ $t('Date') }} </b></a-col>
              <a-col :style="{marginLeft: '3px'}">:</a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.user.created_at}}</a-col>
            </a-row>

          </a-col>

        </a-row>

      </div>

  
      <template #footer>
          <div style="display:flex; justify-content:space-between; align-items:center">
            <el-button type="danger" plain :icon="Delete" @click="handleDelete(props.user)" v-if="showDelete()"/>
            <el-button type="primary" plain :icon="EditPen" @click="handleEdit(record)"/>
          </div>
      </template>
  </el-dialog>

</template>

<style scoped>
.content-container {
  text-align: left;
}

.left-align {
  text-align: left;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}
</style>

<script setup>

import {ref, onMounted, defineEmits, defineProps} from 'vue';
import {
  EditPen, Delete,
} from '@element-plus/icons-vue'
import { useAuthStore } from '@/store/store';

const props = defineProps({
  user: Object
});

let showModalVisible = ref(false);
const emits = defineEmits(['cancel:viewModal', 'handleEdit', 'handleDelete'])


onMounted(async ()=>{
  showModalVisible.value = true;

});

const showDelete = () => {
  let user = useAuthStore().getUser;
  return user.id !== props.user.id;
}

const handleReset = () => {

  showModalVisible.value = false;

  emits('cancel:showViewModal');
};

const handleEdit = () => {
  showModalVisible.value = false;
  emits('handleEdit');
}

const handleDelete = (record) =>{
  emits('handleDelete', record);
}
</script>