<script setup>
import SiderComponent from "@/components/layout/SiderComponent.vue";
import HeaderComponent from "@/components/layout/HeaderComponent.vue";

const fullYear = new Date().getFullYear();

</script>
<template>
  <a-layout
      style="overflow-x: auto"
  >
    <SiderComponent />
    <a-layout>
      <HeaderComponent></HeaderComponent>
      <a-layout-content style="margin: 0 16px; height: 90vh; overflow-y: auto; overflow-x: auto;">
        <slot></slot>
      </a-layout-content>
      <a-layout-footer style="text-align: center;">
        {{ $t('Copyright') }} © {{ fullYear }} <b><a href="https://neutron.com.bd" target="_blank">Neutron</a></b>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<style>
footer.ant-layout-footer{
  padding: 16px 50px!important;
}
</style>

