<template>

  <el-dialog
      :title="$t('Edit Review')"
      v-model="modalVisible"
      width="80%"
      :before-close="handleReset"
      align-center
  >
    <el-form :model="formData" ref="formRef" :rules="formRules" label-position="left" label-width="200px"
    style="max-height: 60vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">

      <el-form-item :label="$t('Title')+':'" prop="title" :error="getError('title')">
        <el-input type="text"
                  v-model="formData.title" :placeholder="$t('Title')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Url')+':'" prop="title" :error="getError('url')">
        <el-input type="text"
                  v-model="formData.url" :placeholder="$t('Url')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Author')+':'" prop="author" :error="getError('author')">
        <el-input type="text"
                  v-model="formData.author" :placeholder="$t('Author')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Email')+':'" prop="author_email" :error="getError('author_email')">
        <el-input type="email"
                  v-model="formData.author_email" :placeholder="$t('Email')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item v-if="props.type!=='users'" :label="$t(`${capitalizeFirstLetter(props.contentName)}`)" prop="content_id" :error="getError('content_id')">
        <el-select v-model="formData.content_id" :placeholder="$t('Select')" style="width: 100%;">
          <el-option
              v-for="item in contents"
              :key="item.id"
              :label="item.title"
              :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Created At')+':'" prop="created_at" :error="getError('created_at')">
        <el-date-picker
            v-model="formData.created_at"
            type="Date"
            :placeholder="$t('Pick a day')"
            size="default"
            style="width: 100%;"
        />
      </el-form-item>

      <el-form-item :label="$t('Status')" prop="status" :error="getError('status')">
        <el-select v-model="formData.status" :placeholder="$t('Select Status')" style="width: 100%;">
          <el-option
              v-for="item in allStatus"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>
      
      <el-form-item v-if="formErrors.content" :error="formErrors.content[0]">
        <template v-slot:error>
          <span style="color: red;">{{ formErrors.content[0] }}</span>
        </template>
      </el-form-item>
      <QuillEditor theme="snow" :modules="quillModules"
                   toolbar="full"
                   v-model:content="formData.content"
                   ref='quillRef' :style="{ minHeight: '200px' }"
                   :placeholder="$t('Description')" required
                   content-type="html"
      />

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleReset">{{ $t('Cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit()" :disabled="isSubmit">
          {{ $t('Confirm') }}
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script setup>

import { ref, onMounted, defineEmits, defineProps } from 'vue';
import apiCaller from "@/apiCalls/apiCaller";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {QuillEditor} from "@vueup/vue-quill";
import {quillModules} from "@/modules/quillModules";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
let isSubmit = ref(false);

const props = defineProps({
  type: String,
  contentName: String,
  review: Object
});

let formErrors = ref({});
const quillRef = ref(null);
let modalVisible = ref();

const allStatus = [
  'active',
  'draft'
];

const formData = ref({
  type: '',
  title: '',
  url: '',
  author: '',
  author_email: '',
  content_id: '',
  content: '',
  status: '',
  created_at: ''
});

let contents = ref([]);

let contentParams = ref({
  type: '',
  currentPage: -1,
  search: ''
});


const formRules = ref({
  title: [
    { required: true, message: t('Please Enter The Title'), trigger: 'blur' }
  ],

  url: [
    { required: true, message: t('Please Enter The Url'), trigger: 'blur' }
  ],


  content_id: [
    { required: true, message: t('Please Enter The Category'), trigger: 'blur' }
  ],

  content: [
    { required: true, message: t('Please Enter The Content'), trigger: 'blur' }
  ],

  author: [
    { required: true, message: t('Please Enter The Author'), trigger: 'blur' }
  ],

  author_email: [
    { required: true, message: t('Please Enter The Email'), trigger: 'blur' }
  ],

  status: [
    { required: true, message: t('Please Select A Status'), trigger: 'blur' }
  ],

  created_at: [
    { required: true, message: t('Please Select A Date'), trigger: 'blur' }
  ],

});

const formRef = ref(null);

function getUrl()
{
  if(props.type === 'products')
  {
    return 'products';
  }

  return 'contents';
}


onMounted(async ()=>{
  modalVisible.value = true;
  formData.value.type = props.type;
  contentParams.value.type = props.type;
  formData.value.title = props.review.title;
  formData.value.author = props.review.author;
  formData.value.author_email = props.review.author_email;
  formData.value.content_id = props.review.reviewable_id;
  formData.value.content = props.review.review;
  formData.value.status = props.review.status;
  formData.value.created_at = props.review.created_at;
  formData.value.url = props.review.url;


  const res = await apiCaller.get(getUrl(), contentParams.value);

  contents.value = res.data.data;

});

const emits = defineEmits(['cancel:showEditModal', 'saved:showEditModal'])

const handleSubmit = () => {

  formRef.value.validate((valid) => {
    if (valid) {
      isSubmit.value = true;

      const data = new FormData();

      data.append('type', formData.value.type);
      data.append('title', formData.value.title);
      data.append('author', formData.value.author);
      data.append('author_email', formData.value.author_email);
      data.append('content_id', formData.value.content_id);
      data.append('content', formData.value.content);
      data.append('status', formData.value.status);
      data.append('type', formData.value.type);
      data.append('url', formData.value.url);

      data.append('created_at', new Date(formData.value.created_at).toISOString().split('T')[0]);

      data.append('_method', 'PUT');

      apiCaller.post('reviews/'+ props.review.id, data)
          .then(() => {
            emits('saved:showEditModal');
            isSubmit.value = false;
          }).catch(error => {
            formErrors.value = error.response.data.data;
            isSubmit.value = false;
          });
    }
  });
};

const handleReset = () => {
  formRef.value.resetFields();
  modalVisible.value = false;
  emits('cancel:showEditModal');
};

function getError(key)
{
  if(formErrors.value[key])
  {
    return formErrors.value[key][0];
  }
}

function capitalizeFirstLetter(type)
{
  return type.charAt(0).toUpperCase() + type.slice(1);
}

</script>