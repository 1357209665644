<template>
  <LayoutComponent>
    <h4 class="m-4 mb-md-3" style="text-align: left;">{{ $t('Reviews') }}</h4>

    <div class="page-wrapper">

      <div class="page-head">

        <div class="search-container">
          <el-input
              type="text"
              style="width: 300px;"
              :placeholder="$t('search')"
              v-model="params.search"
          >

            <template #append>
              <el-button :icon="Search" @click="handleFilter"/>
            </template>
          </el-input>

        </div>
        <el-button type="primary" @click="openCreateModal" :icon="Plus">{{ $t('New') }}</el-button>


      </div>


      <div class="page-content-wrapper">
        <div v-if="listDataLoading">
            <a-skeleton active :paragraph="{ rows: 9 }"/>
        </div>
        <div v-else>
          <a-table
              :columns="columns"
              :data-source="data"
              :pagination="{ current: params.currentPage, pageSize: 10, total: totalRecords }"
              :style="{ width: '100%', overflowX: 'auto' }"
              :scroll="{x: true}"
              @change="handlePaginationChange"

          >

            <template #headerCell="{ column }">
              <span v-if="column.dataIndex !=='featured'">{{ $t(`${column.title}`) }}</span>
            </template>

            <template #bodyCell="{ column, record, text }">
              <template v-if="column.dataIndex === 'featured'">
                <div style="margin-top: -10px">
                  <label>
                    <input type="checkbox" class="star-checkbox" @click="toggleFeatured(record.id)" :checked="text===1"/>
                    <div class="star-label" :checked="text===1"></div>
                  </label>
                </div>
              </template>
            </template>

            <template #actions="{ record }">
              <el-button type="primary" plain :icon="View" @click="handleShow(record)"/>
            </template>

          </a-table>
        </div>
      </div>
      <ReviewCreator v-if="showModal" :type="props.type" :contentName="props.contentColumn" @cancel:showModal="hideModal" @saved:show-modal="refresh"/>
      <ReviewViewComponent v-if="showViewModal" :review="content" :contentColumn="props.contentColumn"  @cancel:showViewModal="hideViewModal" @handleEdit="handleEdit" @handleDelete="handleDelete"/>
      <ReviewEditor v-if="showEditModal" :review="content" :type="props.type" :contentName="props.contentColumn" @cancel:showEditModal="hideEditModal" @saved:showEditModal="refreshEdit"/>

    </div>

  </LayoutComponent>
</template>

<style scoped>
.page-wrapper {
  background: #fff;
  margin-top: 16px;
}

.page-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid #ecf3fa;
}

.page-title {
  font-size: 22px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
}

.page-content-wrapper {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.search-container {
  position: relative;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
  box-sizing: border-box;
}

.search-button {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background-color: #0c85d0;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

</style>

<script setup>

import {onMounted, ref, defineProps} from "vue";
import {Plus, View} from "@element-plus/icons-vue";
import LayoutComponent from "@/components/layout/LayoutComponent.vue";
import ReviewEditor from "@/components/common/editors/ReviewEditor.vue";
import apiCaller from "@/apiCalls/apiCaller";
import Swal from "sweetalert2";
import {Search} from "@element-plus/icons-vue";
import ReviewCreator from "@/components/common/creators/ReviewCreator.vue";
import ReviewViewComponent from "@/components/common/views/ReviewViewComponent.vue";
import {ElLoading} from "element-plus";

const props = defineProps({
  type: String,
  contentColumn: String
});

let showModal = ref(false);
let showViewModal = ref(false);
let showEditModal = ref(false);
let listDataLoading = ref(false);

const type = ref({
  type: 'reviews'
});

const columns = [
  {
    title: "⭐",
    dataIndex: "featured",
    width: 10,

  },
  {
    title: 'Title',
    dataIndex: 'title',

  },
  ...(props.type === 'users'
      ? []
      : [
        {
          title: capitalizeFirstLetter(props.contentColumn),
          dataIndex: 'content_name',
        },
      ]),

  {
    title: 'Status',
    dataIndex: 'status',

  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    slots: {customRender: 'actions'},

  },
];

let data = ref([]);
let content = ref({});

let params = ref({
  type: 'pages',
  currentPage: 1,
  search: ''
});

let totalRecords = ref(10);

async function fetchData() {
   listDataLoading.value = true;
  data.value = [];
  const res = await apiCaller.get('reviews', params.value);

  data.value = res.data.data.data;
  totalRecords.value = res.data.data.total;
   listDataLoading.value = false;
}

const toggleFeatured = async (id) => {

  const loadingInstance = ElLoading.service({ fullscreen: true });
  await apiCaller.post('feature/'+id, type.value);
  fetchData();
  loadingInstance.close();
}


onMounted(async () => {
  document.title = props.type.charAt(0).toUpperCase() + props.type.slice(1) + ' | Reviews';
  params.value.type = props.type;
  fetchData();
});

async function handlePaginationChange(pagination) {
  params.value.currentPage = pagination.current;

  fetchData();
}

function handleFilter() {
  fetchData();
}

function openCreateModal() {
  showModal.value = true;
}

function hideModal() {
  showModal.value = false;
}

function refresh() {
  fetchData();
  hideModal();
}

const handleShow = record => {
  content.value = record;

  showViewModal.value = true;
};

const handleDelete = async (record) => {
  showViewModal.value = false;

  const result = await Swal.fire({
    icon: 'warning',
    title: 'Are you sure?',
    text: "This action can't be reverted",
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
  });

  if (result.isConfirmed) {
    try {
      const url = 'reviews/'+ record.id;
      await apiCaller.delete(url);

      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Your record has been deleted.',
      });

      fetchData();

    } catch (error) {

      console.error('Error deleting record:', error);


      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to delete the record. Please try again.',
      });
    }
  }
}

function hideViewModal()
{
  showViewModal.value = false;
}

function handleEdit()
{
  hideViewModal();
  showEditModal.value = true;
}

function hideEditModal()
{
  showEditModal.value = false;
}

function refreshEdit()
{
  fetchData();
  showEditModal.value = false;
}

function capitalizeFirstLetter(type)
{
  return type.charAt(0).toUpperCase() + type.slice(1);
}

</script>