<template>

    <el-dialog
        :title="$t('Edit Product')"
        v-model="modalVisible"
        width="80%"
        :before-close="handleReset"
        align-center
    >
      <div v-if="loadingData">
        <a-skeleton active :paragraph="{ rows: 10 }"/>
      </div>
      <div v-else>
        <el-form :model="formData" ref="formRef" :rules="formRules" label-position="left" label-width="200px"
        style="max-height: 60vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">


          <el-form-item :label="$t('Title')+':'" prop="title" :error="getError('title')">
            <el-input type="text"
                      v-model="formData.title" :placeholder="$t('Title')" aria-required="true">
            </el-input>

          </el-form-item>

          <el-form-item :label="$t('Url')+':'" prop="title" :error="getError('url')">
            <el-input type="text"
                      v-model="formData.url" :placeholder="$t('Url')" aria-required="true">
            </el-input>

          </el-form-item>

          <el-form-item :label="$t('Excerpt')+':'" prop="excerpt" :error="getError('excerpt')">
            <el-input type="text"
                      v-model="formData.excerpt" :placeholder="$t('Excerpt')" aria-required="true">
            </el-input>

          </el-form-item>
          <el-form-item :label="$t('Cover Image')" prop="cover_image" :error="getError('cover_image')">
            <input
                type="file"
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                @change="uploadCoverImage"
                ref="file"
                accept="image/*"
            />

            <div v-if="formData.cover_image">
              <img :src="imageUrl" :alt="$t('Cover Image')" style="max-width: 50%; max-height: 100px; margin-top: 10px;">
            </div>

          </el-form-item>

          <el-form-item :label="$t('Images')">
            <input
                type="file"
                multiple
                @change="uploadImages"
                ref="file"
                accept="image/*"
            />

          </el-form-item>

          <div class="demo-image">
            <div v-for="(image, index) in imageUrls" :key="index" class="block"  @mouseover="showCloseButton" @mouseleave="hideCloseButton">
              <img style="width: 100px; height: 100px" :src="image" fit="fill" />
              <el-button
                  v-show="showClose"
                  class="close-button"
                  :icon="Close"
                  @click="removeImage(image)">
              </el-button>
            </div>
          </div>

          <el-form-item :label="$t('Category')" prop="category_id" :error="getError('category_id')">
            <el-select v-model="formData.category_id" :placeholder="$t('Select Category')" style="width: 100%;">
              <el-option
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
              />
            </el-select>

          </el-form-item>

          <el-form-item :label="$t('Tax')+':'" prop="tax" :error="getError('tax')">
            <el-input type="number"
                      v-model="formData.tax" :placeholder="$t('Tax')" aria-required="true">
            </el-input>

          </el-form-item>

          <el-form-item :label="$t('Price')+':'" prop="price" :error="getError('price')">
            <el-input type="number"
                      v-model="formData.price" :placeholder="$t('Price')" aria-required="true">
            </el-input>

          </el-form-item>


          <el-form-item :label="$t('Sale')+':'" prop="sale" :error="getError('sale')">
            <el-input type="number"
                      v-model="formData.sale" :placeholder="$t('Sale')" aria-required="true">
            </el-input>

          </el-form-item>


          <el-form-item :label="$t('Stock')+':'" prop="stock" :error="getError('stock')">
            <el-input type="number"
                      v-model="formData.stock" :placeholder="$t('Stock')" aria-required="true">
            </el-input>

          </el-form-item>

          <el-form-item :label="$t('Status')" prop="status" :error="getError('status')">
            <el-select v-model="formData.status" :placeholder="$t('Select Status')" style="width: 100%;">
              <el-option
                  v-for="item in allStatus"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('Created At')+':'" prop="created_at" :error="getError('created_at')">
            <el-date-picker
                v-model="formData.created_at"
                type="Date"
                :placeholder="$t('Pick a day')"
                size="default"
                style="width: 100%;"
            />
          </el-form-item>

          <el-form-item v-if="formErrors.content" :error="formErrors.content[0]">
            <template v-slot:error>
              <span style="color: red;">{{ formErrors.content[0] }}</span>
            </template>
          </el-form-item>

          <QuillEditor theme="snow"
                      toolbar="full"
                      v-model:content="formData.content"
                      :modules="quillModules"
                      ref='quillRef' :style="{ minHeight: '200px' }"
                      :placeholder="$t('Description')" required
                      content-type="html"
          />


        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleReset">{{ $t('Cancel') }}</el-button>
          <el-button type="primary" @click="handleSubmit()" :disabled="isSubmit">
            {{ $t('Confirm') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
 
</template>

<style scoped>
.demo-image .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 20%;
  box-sizing: border-box;
  vertical-align: top;
}
.demo-image .block:last-child {
  border-right: none;
}

.block {
  position: relative;
  display: inline-block;
  margin: 5px;
  overflow: hidden;
  transition: filter 0.3s ease;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  cursor: pointer;
  display: none;
}

.block:hover .close-button {
  display: block;
}

</style>

<script setup>

import { ref, onMounted, defineEmits, defineProps } from 'vue';
import apiCaller from "@/apiCalls/apiCaller";
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {Close} from "@element-plus/icons-vue";
import {imageUploader} from "@/apiCalls/imageUploader";
import {quillModules} from "@/modules/quillModules";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  product: Object
});

let formErrors = ref({});
let modalVisible = ref();
let showClose = ref(false);
let loadingData = ref(true);
let isSubmit = ref(false);

const allStatus = [
  'active',
  'draft'
];

const formData = ref({
  title: '',
  url: '',
  excerpt: '',
  cover_image: '',
  category_id: '',
  content: '',
  tax: '',
  stock: '',
  sale: '',
  price: '',
  images: [],
  status: '',
  created_at: ''
});

let categories = ref([]);
let imageUrls = ref([]);


let categoryParams = ref({
  type: 'products',
  currentPage: -1,
  search: ''
});

let imageUrl = ref();

const formRules = ref({
  title: [
    { required: true, message: t('Please Enter The Title'), trigger: 'blur' }
  ],

  url: [
    { required: true, message: t('Please Enter The Url'), trigger: 'blur' }
  ],

  excerpt: [
    { required: true, message: t('Please Enter The Excerpt'), trigger: 'blur' }
  ],
  cover_image: [
    { required: false, message: t('Please Upload An Image'), trigger: 'change' },
  ],
  category_id: [
    { required: true, message: t('Please Enter The Category'), trigger: 'blur' }
  ],

  content: [
    { required: true, message: t('Please Enter The Content'), trigger: 'blur' }
  ],

  tax: [
    { required: true, message: t('Please Enter Tax'), trigger: 'blur' }
  ],

  stock: [
    { required: true, message: t('Please Enter The Stock'), trigger: 'blur' }
  ],

  sale: [
    { required: true, message: t('Please Enter The Sale'), trigger: 'blur' }
  ],

  price: [
    { required: true, message: t('Please Enter The Price'), trigger: 'blur' }
  ],

  status: [
    { required: true, message: t('Please Select A Status'), trigger: 'blur' }
  ],

  created_at: [
    { required: true, message: t('Please Select A Date'), trigger: 'blur' }
  ],
});

const formRef = ref(null);
const quillRef = ref(null);

onMounted(async ()=> {
  modalVisible.value = true;

  loadingData.value = true;
  
  const res = await apiCaller.get('categories', categoryParams.value);
  
  categories.value = res.data.data;

  formData.value.cover_image = props.product.cover_image_path;
  imageUrl.value = props.product.image_url;

  props.product.images.forEach(function (image)
      {
        imageUrls.value.push(image.image_url);
        formData.value.images.push(image.path);
      }
  );

  formData.value.title = props.product.title;
  formData.value.excerpt = props.product.excerpt;
  formData.value.category_id = props.product.category_id;
  formData.value.content = props.product.content;
  formData.value.tax = props.product.tax;
  formData.value.sale = props.product.sale;
  formData.value.price = props.product.price;
  formData.value.status = props.product.status;
  formData.value.stock = props.product.stock;
  formData.value.created_at = props.product.created_at;
  formData.value.url = props.product.url;

  loadingData.value = false;
});

const emits = defineEmits(['cancel:showEditModal', 'saved:showEditModal'])

const handleSubmit = () => {
  
  formRef.value.validate((valid) => {
    if (valid) {
      isSubmit.value = true;

      const data = new FormData();
      data.append('title', formData.value.title);
      data.append('excerpt', formData.value.excerpt);
      data.append('cover_image', formData.value.cover_image);
      data.append('category_id', formData.value.category_id);
      data.append('content', formData.value.content);
      data.append('tax', formData.value.tax);
      data.append('stock', formData.value.stock);
      data.append('sale', formData.value.sale);
      data.append('price', formData.value.price);
      data.append('status', formData.value.status);
      data.append('created_at', new Date(formData.value.created_at).toISOString().split('T')[0]);
      data.append('url', formData.value.url);

      data.append('_method', 'PUT');

      for (let i = 0; i < formData.value.images.length; i++) {
        data.append(`images[${i}]`, formData.value.images[i]);
      }

      apiCaller.post('products/'+ props.product.id, data)
          .then(() => {
            emits('saved:showEditModal');
            isSubmit.value = false;
          }).catch(error => {
             formErrors.value = error.response.data.data;
              isSubmit.value = false;
           });
    }
    
  });
};

const handleReset = () => {
  formRef.value.resetFields();
  modalVisible.value = false;
  emits('cancel:showEditModal');
};

async function uploadCoverImage(e)
{
  if (!e.target.files[0].type.startsWith('image/')) {
    alert('Please select only image files.');
    return;
  }

  let images = [];
  images = e.target.files;

  const res = await imageUploader.handleFilelUpload(images);
  imageUrl.value = res.data.data.url;
  formData.value.cover_image = res.data.data.path;
}

async function uploadImages(e)
{
  if (!e.target.files[0].type.startsWith('image/')) {
    alert('Please select only image files.');
    return;
  }

  let images = [];
  images = e.target.files;

  if(images.length > 0){
    const res =await imageUploader.handleFilelUpload(images);

    res.data.data.url.forEach(function (url)
        {
          imageUrls.value.push(url);
        }
    );

    res.data.data.path.forEach(function (path)
        {
          formData.value.images.push(path);
        }
    );
  }

}

function showCloseButton() {
  showClose.value = true;
}

function hideCloseButton() {
  showClose.value = false;
}

function removeImage(image)
{
  const index = imageUrls.value.indexOf(image);
  imageUrls.value.splice(index, 1);
  formData.value.images.splice(index, 1);

  var url = image;
  var newStr = url.indexOf("images/") + "images/".length;
  var newUrl = url.substring(newStr);

  apiCaller.delete('image/'+newUrl).then(()=>{})
}

function getError(key)
{
  if(formErrors.value[key])
  {
    return formErrors.value[key][0];
  }
}

</script>