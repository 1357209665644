<template>

  <el-dialog
      v-model="showModalVisible"
      :title="props.product.title"
      width="60%"
      :before-close="handleReset"
  >
      <div style="max-height: 40vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">
        
        <a-row>
          <a-col :span="12">
            <img :src="props.product.image_url" style="width: 80%; height: 80%; display: block; margin: auto">

          </a-col>
          <a-col :span="12">
            <div class="all-image__lazy">
              <img class="image" v-for="image in props.product.images" :key="image.id" :src="image.image_url" loading="lazy"
              style="width: 80%; height: 80%;"/>
            </div>
          </a-col>
        </a-row>
      </div>

      <div style="padding: 10px;">
          <a-row>
            <a-col>
              <a-row>
                <a-col><h5>{{ $t('Price') }}: </h5></a-col>
                <a-col :style="{marginLeft: '5px'}">{{props.product.price}}</a-col>
              </a-row>

            </a-col>
            <a-col :style="{marginLeft: '100px'}">
              <a-row>
                <a-col><h5>{{ $t('Sale') }}: </h5></a-col>
                <a-col :style="{marginLeft: '5px'}">{{props.product.sale}}</a-col>
              </a-row>
            </a-col>

            <a-col :style="{marginLeft: '100px'}">
              <a-row>
                <a-col><h5>{{ $t('Tax') }}: </h5></a-col>
                <a-col :style="{marginLeft: '5px'}">{{props.product.tax}}</a-col>
              </a-row>
            </a-col>

            <a-col :style="{marginLeft: '100px'}">
              <a-row>
                <a-col><h5>{{ $t('Stock') }}: </h5></a-col>
                <a-col :style="{marginLeft: '5px'}">{{props.product.stock}}</a-col>
              </a-row>
            </a-col>

          </a-row>

        </div>

      <template #footer>
        <h4 style="text-align: center;">{{props.product.title}}</h4>
        <div class="content-container">
          <div v-html="props.product.content"></div>
        </div>
        <div style="display:flex; justify-content:space-between; align-items:center">
          <el-button type="danger" plain :icon="Delete" @click="handleDelete(props.product)"/>
          <el-button type="primary" plain :icon="EditPen" @click="handleEdit(record)"/>
        </div>
      </template>

  </el-dialog>

</template>

<style scoped>
.content-container {
  text-align: left;
}

.left-align {
  text-align: left;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}

.all-image__lazy {
  height: 400px;
}

.all-image__lazy .image {
  display: block;
  min-height: 200px;
  margin-bottom: 10px;
}

.all-image__lazy .el-image:last-child {
  margin-bottom: 0;
}
</style>

<script setup>
import {ref, onMounted, defineEmits, defineProps} from 'vue';
import {
  EditPen, Delete
} from '@element-plus/icons-vue'

const props = defineProps({
  product: Object,
});

let showModalVisible = ref(false);
const emits = defineEmits(['cancel:viewModal', 'handleEdit', 'handleDelete'])

onMounted(async ()=>{
  showModalVisible.value = true;
});

const handleReset = () => {
  showModalVisible.value = false;
  emits('cancel:showViewModal');
};

const handleEdit = () => {
  showModalVisible.value = false;
  emits('handleEdit');
}

const handleDelete = (record) =>{
  emits('handleDelete', record);
}
</script>