import './assets/css/global.css';
import App from './App.vue';
import router from "@/router/router";
import { pinia } from "@/store/store";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { createApp, watch, reactive } from "vue";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import { createI18n } from 'vue-i18n'
import axios from 'axios';

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang') ?? 'en',
    fallbackLocale: 'en',
    messages: {
        cn: {
        }
    },
})

const localeFetched = reactive({
    locale: null
})

watch(() => localeFetched.locale, () => {
    i18n.global.setLocaleMessage('cn', localeFetched.locale)
    i18n.global.setLocaleMessage('en', {})
});

axios.get(process.env.VUE_APP_BACKEND_API_URL+'translations/list').then((res)=>{
    localeFetched.locale = res.data.data;
}).catch (()=>{
    localeFetched.locale = []
});

const app = createApp(App);

app.use(VueSweetalert2).
    use(Antd).
    use(ElementPlus).
    use(router).
    use(pinia).
    use(i18n).
    mount('#app');