<template>
  <ContentListComponent :type="type"/>

</template>

<script setup>
import ContentListComponent from "@/components/common/lists/ContentListComponent.vue";
import {ref} from "vue";

const type = ref('Jobs');

</script>