<template>

  <el-dialog
      :title="$t('Create Video')"
      v-model="modalVisible"
      width="80%"
      :before-close="handleReset"
      align-center
  >
    <el-form :model="formData" ref="formRef" :rules="formRules" label-position="left" label-width="200px"
    style="max-height: 60vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">

      <el-form-item :label="$t('Title')+':'" prop="title" :error="getError('title')">
        <el-input type="text" @input="titleChanged"
                  v-model="formData.title" :placeholder="$t('Title')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Url')+':'" prop="url" :error="getError('url')">
        <el-input type="text"
                  v-model="formData.url" :placeholder="$t('Url')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Excerpt')+':'" prop="excerpt" :error="getError('excerpt')">
        <el-input type="text"
                  v-model="formData.excerpt" :placeholder="$t('Excerpt')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Video')" prop="video" :error="getError('video')">
        <input
            type="file"
            name="fields[assetsFieldHandle][]"
            id="assetsFieldHandle"
            @change="handleSuccessfulUpload"
            ref="file"
            accept="video/*"
        />

        <div v-if="videoUrl">
           <video width="220" height="140" controls autoplay muted name="media">
            <source :src="videoUrl" type="video/mp4">
          </video> 
        </div>
      </el-form-item>

      <el-form-item :label="$t('Category')" prop="category_id">
        <el-select v-model="formData.category_id" :placeholder="$t('Select Category')" style="width: 100%;">
          <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.title"
              :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Status')" prop="status">
        <el-select v-model="formData.status" :placeholder="$t('Select Status')" style="width: 100%;">
          <el-option
              v-for="item in allStatus"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>

      <el-form-item v-if="formErrors.content" :error="formErrors.content[0]">
        <template v-slot:error>
          <span style="color: red;">{{ formErrors.content[0] }}</span>
        </template>
      </el-form-item>

      <QuillEditor theme="snow" toolbar="full"
        :modules="quillModules"
        v-model:content="formData.content"
        ref='quillRef' :style="{ minHeight: '200px' }"
        :placeholder="$t('Description')"
        content-type="html"
      />

    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleReset">{{ $t('Cancel') }}</el-button>
        <el-button v-if="submitting === false" type="primary" @click="handleSubmit()">
          {{  $t('Confirm') }}
        </el-button>
        <el-button v-if="submitting === true" type="primary">
          {{  $t('Wait') }}
        </el-button>
      </span>
    </template>

  </el-dialog>

</template>

<script setup>
import { ref, onMounted, defineEmits } from 'vue';
import apiCaller from "@/apiCalls/apiCaller";
import { QuillEditor } from '@vueup/vue-quill';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emits = defineEmits(['cancel:showModal', 'saved:showModal'])

let formErrors = ref({});
const formRef = ref(null);
let modalVisible = ref();
let categories = ref([]);
let videoUrl = ref();
let submitting = ref(false);

const allStatus = [
  'ACTIVE',
  'INACTIVE'
];

let categoryParams = ref({
  type: 'videos',
  currentPage: 0,
  search: ''
});

const validateVideoType = (rule, file, callback) => {
  if(!file)
    {
      callback();
    } else {
      const allowedTypes = ['video/mp4', 'video/avi', 'video/flv', 'video/mov', 'video/wmv'];
      let fileSize = (file.size / 1048576).toFixed(2) // fileSize in MB

      if (!allowedTypes.includes(file.type)) {
        callback(new Error('Invalid video type. Please upload a MP4, AVI, FLV, MOV or WMV.'));
      }
      else if(fileSize > 20){
         callback(new Error('File size must be less than 20MB.'));
      }
      else {
        callback();
      }
    }
};

const formData = ref({
  category_id: '',
  title: '',
  url: '',
  excerpt: '',
  video: null,
  status: 'active',
  content: ''
});

const formRules = ref({
  category_id: [
    { required: false, message: t('Please Enter The Category'), trigger: 'blur' }
  ],

  url: [
    { required: true, message: t('Please Enter The Url'), trigger: 'blur' }
  ],

  title: [
    { required: true, message: t('Please Enter The Title'), trigger: 'blur' }
  ],
  excerpt: [
    { required: true, message: t('Please Enter The Excerpt'), trigger: 'blur' }
  ],
  video: [
    { required: true, message: t('Please Upload A Video'), trigger: 'change' },
    { validator: validateVideoType, trigger: 'change' },
  ],
  status: [
    { required: false, message: t('Please Enter The Status'), trigger: 'blur' }
  ],
  content: [
    { required: false, message: t('Please Enter The Content'), trigger: 'blur' }
  ]
});

onMounted(async ()=> {
  modalVisible.value = true;

  const res = await apiCaller.get('categories', categoryParams.value);
  categories.value = res.data.data;
});

const handleSubmit = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      submitting.value = true;

      const data = new FormData();
      data.append('title', formData.value.title);
      data.append('excerpt', formData.value.excerpt);
      data.append('video', formData.value.video);
      data.append('category_id', formData.value.category_id);
      data.append('status', formData.value.status);
      data.append('content', formData.value.content);
      data.append('url', formData.value.url);

      apiCaller.post('videos', data).then(() => {
          emits('saved:showModal');
          submitting.value = false;
      }).catch(error => {
          formErrors.value = error.response.data.data;
          submitting.value = false;
      });

    } else {
      submitting.value = false;
    }
  });
};

const handleReset = () => {
  formRef.value.resetFields();
  modalVisible.value = false;
  emits('cancel:showModal');
};

function handleSuccessfulUpload(e) {
  if (!e.target.files[0].type.startsWith('video/')) {
    alert('Please select only video files.');
    return;
  }

  videoUrl.value = null;

  formData.value.video = e.target.files[0];
  let reader = new FileReader();
  reader.readAsDataURL(e.target.files[0]);
  reader.onloadend = event => {
    videoUrl.value = event.target.result;
  };
}

function getError(key)
{
  if(formErrors.value[key])
  {
    return formErrors.value[key][0];
  }
}

const titleChanged = () => {
  formData.value.url = formData.value.title;
}


</script>