<template>

  <ReviewList :type="type" :contentColumn="contentColumn"/>

</template>

<script setup>
import ReviewList from "@/components/common/lists/ReviewList.vue";
import {ref} from "vue";

const type = ref('jobs');
const contentColumn = ref('Job');

</script>