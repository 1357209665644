<template>

  <el-dialog
      :title="$t('Edit Logo')"
      v-model="modalVisible"
      width="80%"
      :before-close="handleReset"
      align-center
  >
    <el-form :model="formData" ref="formRef" :rules="formRules" label-position="left" label-width="200px"
    style="max-height: 60vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">

      <el-form-item :label="$t('Title')+':'" prop="title" :error="getError('title')">
        <el-input type="text"
                  v-model="formData.title" :placeholder="$t('Title')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Url')+':'" prop="url" :error="getError('url')">
        <el-input type="text"
                  v-model="formData.url" :placeholder="$t('Url')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Excerpt')+':'" prop="excerpt" :error="getError('excerpt')">
        <el-input type="text"
                  v-model="formData.excerpt" :placeholder="$t('Excerpt')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Logo Image')" prop="logo_image" :error="getError('logo_image')">
        <input
            type="file"
            name="fields[assetsFieldHandle][]"
            id="assetsFieldHandle"
            @change="handleSuccessfulUpload"
            ref="file"
            accept="image/*"
        />

        <div v-if="formData.logo_image">
          <img :src="imageUrl" :alt="$t('Logo Image')" style="max-width: 50%; max-height: 100px; margin-top: 10px;">
        </div>
      </el-form-item>

      <el-form-item :label="$t('Category')" prop="category_id" :error="getError('category_id')">
        <el-select v-model="formData.category_id" :placeholder="$t('Select Category')" style="width: 100%;">
          <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.title"
              :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Status')" prop="status" :error="getError('status')">
        <el-select v-model="formData.status" :placeholder="$t('Select Status')" style="width: 100%;">
          <el-option
              v-for="item in allStatus"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>
      </el-form-item>

      <el-form-item v-if="formErrors.content" :error="formErrors.content[0]">
        <template v-slot:error>
          <span style="color: red;">{{ formErrors.content[0] }}</span>
        </template>
      </el-form-item>

    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleReset">{{ $t('Cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit()" :disabled="isSubmit">
          {{ $t('Confirm') }}
        </el-button>
      </span>
    </template>

  </el-dialog>

</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue';
import apiCaller from "@/apiCalls/apiCaller";
import {useI18n} from 'vue-i18n';

const {t} = useI18n();
let isSubmit = ref(false);
let formErrors = ref({});
const formRef = ref(null);
let modalVisible = ref();
let categories = ref([]);
let imageUrl = ref();

const props = defineProps({
  logo: Object
});

const allStatus = [
  'ACTIVE',
  'INACTIVE'
];

let categoryParams = ref({
  type: 'logos',
  currentPage: 0,
  search: ''
});

const formData = ref({
  title: '',
  url: '',
  excerpt: '',
  logo_image: null,
  category_id: '',
  status:''
});

const formRules = ref({
  title: [
    { required: true, message: t('Please Enter The Title'), trigger: 'blur' }
  ],

  url: [
    { required: true, message: t('Please Enter The Url'), trigger: 'blur' }
  ],

  excerpt: [
    { required: true, message: t('Please Enter The Excerpt'), trigger: 'blur' }
  ],
  logo_image: [
    { required: false, message: t('Please Upload An Image'), trigger: 'change' },
  ],
  category_id: [
    { required: false, message: t('Please Enter The Category'), trigger: 'blur' }
  ],
});

onMounted(async ()=> {
  modalVisible.value = true;

  const res = await apiCaller.get('categories', categoryParams.value);
  categories.value = res.data.data;

  formData.value.title = props.logo.title;
  formData.value.excerpt = props.logo.excerpt;

  formData.value.logo_image = props.logo.logo_photo_path;
  imageUrl.value = props.logo.image_url;

  formData.value.category_id = props.logo.category_id === null? '': props.logo.category_id;
  formData.value.status = props.logo.status;
  formData.value.url = props.logo.url;

});

const emits = defineEmits(['cancel:showEditModal', 'saved:showEditModal'])

const handleSubmit = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      isSubmit.value = true;

      const data = new FormData();
      data.append('title', formData.value.title);
      data.append('excerpt', formData.value.excerpt);
      data.append('logo_image', formData.value.logo_image);
      data.append('category_id', formData.value.category_id);
      data.append('status', formData.value.status);
      data.append('url', formData.value.url);

      data.append('_method', 'PUT');
      
      apiCaller.post('logos/'+ props.logo.id, data).then(() => {
          emits('saved:showEditModal');
          isSubmit.value = false;
      }).catch(error => {
          formErrors.value = error.response.data.data;
          isSubmit.value = false;
      });

    }
  });
};

const handleReset = () => {
  formRef.value.resetFields();
  modalVisible.value = false;
  emits('cancel:showEditModal');
};

function handleSuccessfulUpload(e) {
  if (!e.target.files[0].type.startsWith('image/')) {
    alert('Please select only image files.');
    return;
  }

  formData.value.logo_image = e.target.files[0];
  let reader = new FileReader();
  reader.readAsDataURL(e.target.files[0]);
  reader.onloadend = event => {
    imageUrl.value = event.target.result;
  };
}

function getError(key)
{
  if(formErrors.value[key])
  {
    return formErrors.value[key][0];
  }
}

</script>