<template>

  <el-dialog
      v-model="showModalVisible"
      :title="props.review.title"
      width="60%"
      :before-close="handleReset"
  >
      <div style="max-height: 40vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">
        <a-row>
          <a-col>
            <a-row>
              <a-col><h5>{{ $t('Author') }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.review.author}}</a-col>
            </a-row>

          </a-col>
          <a-col :style="{marginLeft: '100px'}">
            <a-row>
              <a-col><h5>{{ $t('Email') }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}">{{props.review.author_email}}</a-col>
            </a-row>
          </a-col>


        </a-row>
        <a-row>
          <a-col>
            <a-row>
              <a-col><h5>{{ $t('Date') }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}" >{{props.review.created_at}}</a-col>

            </a-row>
          </a-col>

            <a-row v-if="props.contentColumn!=='user'">
              <a-col :style="{marginLeft: '100px'}"><h5>{{ $t(`${props.contentColumn.charAt(0).toUpperCase() + props.contentColumn.slice(1)}`) }}: </h5></a-col>
              <a-col :style="{marginLeft: '5px'}" > {{props.review.reviewable.title}}</a-col>
            </a-row>
        </a-row>

      </div>

      <template #footer>
        <h4 style="text-align: center;">{{props.review.title}}</h4>

        <div class="content-container">
          <div v-html="props.review.review"></div>
        </div>
        <div style="display:flex; justify-content:space-between; align-items:center">
          <el-button type="danger" plain :icon="Delete" @click="handleDelete(props.review)"/>
          <el-button type="primary" plain :icon="EditPen" @click="handleEdit(record)"/>
        </div>

      </template>
  </el-dialog>

</template>

<style scoped>
.content-container {
  text-align: left;
}

.left-align {
  text-align: left;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}
</style>

<script setup>

import {ref, onMounted, defineEmits, defineProps} from 'vue';
import {
  EditPen, Delete
} from '@element-plus/icons-vue'


const props = defineProps({
  review: Object,
  contentColumn: String
});

let showModalVisible = ref(false);
const emits = defineEmits(['cancel:viewModal', 'handleEdit', 'handleDelete'])


onMounted(async ()=>{
  showModalVisible.value = true;
});

const handleReset = () => {

  showModalVisible.value = false;

  emits('cancel:showViewModal');
};

const handleEdit = () => {
  showModalVisible.value = false;
  emits('handleEdit');
}

const handleDelete = (record) =>{
  emits('handleDelete', record);
}
</script>