<template>


  <el-dialog
      v-model="showModalVisible"
      :title="props.logo.title"
      width="60%"
      :before-close="handleReset"
  >
    
    <div style="max-height: 40vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">
        <div>
          <a-row>
            <a-col :span="12">
              <img :src="props.logo.image_url" style="width: 80%; height: 80%; display: block; margin: auto">
            </a-col>

            <a-col :span="12">
              <div class="all-image__lazy">
                <el-image v-for="image in props.logo.images" :key="image.id" :src="image.image_url" lazy />
              </div>
            </a-col>
          </a-row>
        </div>

        <div style="padding: 10px;">
          <a-row>
            <a-col>
              <a-row>
                <a-col><h5>{{ $t('Date') }}: </h5></a-col>
                <a-col :style="{marginLeft: '5px'}">{{props.logo.created_at}}</a-col>
              </a-row>

            </a-col>
            <a-col :style="{marginLeft: '100px'}">
              <a-row>
                <a-col><h5>{{ $t('Category') }}: </h5></a-col>
                <a-col :style="{marginLeft: '5px'}">{{props.logo.category_name}}</a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>

      </div>

      <template #footer>
        <div style="display:flex; justify-content:space-between; align-items:center">
          <el-button type="danger" plain :icon="Delete" @click="handleDelete(props.logo)"/>
          <el-button type="primary" plain :icon="EditPen" @click="handleEdit(record)"/>
        </div>
      </template>
  </el-dialog>

</template>

<style scoped>
.content-container {
  text-align: left;
}

.left-align {
  text-align: left;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}

.all-image__lazy {
  height: 400px;
  overflow-y: auto;
}
.all-image__lazy .el-image {
  display: block;
  min-height: 200px;
  margin-bottom: 10px;
}
.all-image__lazy .el-image:last-child {
  margin-bottom: 0;
}

</style>

<script setup>

import {ref, onMounted, defineEmits, defineProps} from 'vue';
import {
  EditPen, Delete
} from '@element-plus/icons-vue'


const props = defineProps({
  logo: Object,
});

let showModalVisible = ref(false);
const emits = defineEmits(['cancel:viewModal', 'handleEdit', 'handleDelete'])


onMounted(async ()=>{
  showModalVisible.value = true;
});

const handleReset = () => {

  showModalVisible.value = false;

  emits('cancel:showViewModal');
};

const handleEdit = () => {
  showModalVisible.value = false;
  emits('handleEdit');
}

const handleDelete = (record) =>{
  emits('handleDelete', record);
}
</script>