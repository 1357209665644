<template>
  <LayoutComponent>
    <h4 class="m-4 mb-md-3" style="text-align: left;">{{ $t('Translation') }}</h4>

    <div class="page-wrapper">

      <div class="page-head">
        <div class="search-container">
            <el-input
              type="text"
              style="width: 300px;"
              :placeholder="$t('search')"
              v-model="params.search"
            >
              <template #append>
                <el-button :icon="Search" @click="onSearch"/>
              </template>
            </el-input>
        </div>
      </div>

      <div class="page-content-wrapper">
          <a-table 
            :columns="columns" 
            :data-source="translations"
            :pagination="{ current: params.currentPage, total: totalRecords }"
            @change="handlePaginationChange"
          >
            <template #headerCell="{ column }">
                    <span>{{$t(column.title)}}</span>
            </template>
            <template #bodyCell="{ column, record }">
              <template v-if="column.dataIndex === 'edit'">
                <div class="action-btns">
                  <div>
                    <a-input v-model:value="record.input"></a-input>
                  </div>
                  <div style="margin-left: 10px">
                    <a-button @click="update(record.id, record.input)" type="primary">{{ $t('Update') }}</a-button>
                  </div>
                </div>
              </template>
            </template>
          </a-table>
      </div>

    </div>
  </LayoutComponent>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import LayoutComponent from "@/components/layout/LayoutComponent.vue";
import {Search} from "@element-plus/icons-vue";
import apiCaller from "@/apiCalls/apiCaller";


let translations=ref([])
let totalRecords = ref(10);

let params = ref({
  currentPage: 1,
  search: ''
});

const fetchData=async()=>{
  await apiCaller.get('translations', params.value).then((res)=>{
    if(res.data.data.data.length){
      translations.value = [];

        for(let index=0; index<res.data.data.data.length; index++){
            const newItem = {
                id: res.data.data.data[index].id,
                en: res.data.data.data[index].en,
                cn: res.data.data.data[index].cn,
                input: res.data.data.data[index].cn
            }
            translations.value.push(newItem);
        }
        totalRecords.value = res.data.data.total;
    }
  }).catch(()=>{
    translations.value = [];
  });
}

onMounted(() => {
 fetchData();
});

const update=async(id, input)=>{
  const formData = new FormData();
  formData.append("id", id);
  formData.append("cn", input);
  formData.append("_method", 'PUT');

  let res = await apiCaller.post(`translations/${id}`, formData);
  
  if(res.data.data.id==id){
    for(let index=0; translations.value.length>index; index++){
      if(translations.value[index].id == res.data.data.id){
        translations.value[index].cn = res.data.data.cn;
      }
    }
  }
}

async function handlePaginationChange(pagination) {
  params.value.currentPage = pagination.current;

  fetchData();
}

const onSearch=async()=>{
  params.value.currentPage = 1;
  fetchData();
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'English',
    dataIndex: 'en',
  },
  {
    title: 'Chinese',
    dataIndex: 'cn',
  },
  {
    title: 'Edit',
    dataIndex: 'edit'
  }
];

</script>

<style scoped>
.page-wrapper {
  background: #fff;
  margin-top: 16px;
}

.page-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid #ecf3fa;
}
.page-content-wrapper {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.lang-section {
  display: flex;
  align-items: center;
}
.page-head {
  display: flex;
  justify-content: end;
}
.action-btns {
  display: flex;
  align-items: center;
}
</style>