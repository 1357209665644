import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from "@/views/LoginPage.vue";
import DashboardPage from "@/views/DashboardPage.vue";
import { useAuthStore } from "@/store/store";
import PageList from "@/views/pages/PageList.vue";
import PageCategoryList from "@/views/pages/PageCategoryList.vue";
import PostList from "@/views/posts/postList.vue";
import PostCategories from "@/views/posts/PostCategories.vue";
import CaseList from "@/views/cases/CaseList.vue";
import CaseCategories from "@/views/cases/CaseCategories.vue";
import JobList from "@/views/jobs/JobList.vue";
import JobCategories from "@/views/jobs/JobCategories.vue";
import JobReview from "@/views/jobs/JobReview.vue";
import PageReviewList from "@/views/pages/PageReviewList.vue";
import PostReviewList from "@/views/posts/PostReviewList.vue";
import CaseReviewList from "@/views/cases/CaseReviewList.vue";
import ProductList from "@/views/products/ProductList.vue";
import CategoryList from "@/views/products/CategoryList.vue";
import ProductReviewList from "@/views/products/ProductReviewList.vue";
import UserList from "@/views/users/UserList.vue";
import AdminList from "@/views/users/AdminList.vue";
import UserReviews from "@/views/users/UserReviews.vue";
import InquiryList from "@/views/users/InquiryList.vue";
import LogoList from "@/views/logos/LogoList.vue";
import LogoCategoryList from "@/views/logos/LogoCategoryList.vue";
import AllMenu from "@/views/menu/AllMenu.vue";

import VideoList from "@/views/videos/VideoList.vue";
import VideoCategoryList from "@/views/videos/VideoCategoryList.vue";
import SettingPage from "@/views/settings/SettingPage.vue";


const routes = [

    {
        path: '/',
        name: 'Home',
        redirect: '/dashboard',
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DashboardPage,
    },

    {
        path: '/pages',
        name: 'Pages',
        component: PageList,
    },

    {
        path: '/page-categories',
        name: 'Page-categories',
        component: PageCategoryList,
    },

    {
        path: '/posts',
        name: 'posts',
        component: PostList,
    },
    {
        path: '/post-categories',
        name: 'post-categories',
        component: PostCategories,
    },
    {
        path: '/cases',
        name: 'cases',
        component: CaseList,
    },

    {
        path: '/case-categories',
        name: 'cases-categories',
        component: CaseCategories,
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: JobList,
    },

    {
        path: '/job-categories',
        name: 'job-categories',
        component: JobCategories,
    },

    {
        path: '/job-reviews',
        name: 'job-reviews',
        component: JobReview,
    },

    {
        path: '/sliders',
        name: 'sliders',
        component: PageReviewList,
    },

    {
        path: '/post-reviews',
        name: 'post-reviews',
        component: PostReviewList,
    },

    {
        path: '/case-reviews',
        name: 'case-reviews',
        component: CaseReviewList,
    },

    {
        path: '/products',
        name: 'products',
        component: ProductList,
    },

    {
        path: '/product-categories',
        name: 'product-categories',
        component: CategoryList,
    },

    {
        path: '/product-reviews',
        name: 'product-reviews',
        component: ProductReviewList,
    },

    {
        path: '/users',
        name: 'users',
        component: UserList,
    },

    {
        path: '/user-reviews',
        name: 'user-reviews',
        component: UserReviews,
    },

    {
        path: '/user-reviews',
        name: 'user-reviews',
        component: UserReviews,
    },

    {
        path: '/inquiries',
        name: 'inquiries',
        component: InquiryList,
    },

    {
        path: '/admins',
        name: 'admins',
        component: AdminList,
    },

    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
    },
    
    {
        path: '/logos',
        name: 'logos',
        component: LogoList,
    },
    {
        path: '/logo-categories',
        name: 'logo-categories',
        component: LogoCategoryList,
    },
    {
        path: '/videos',
        name: 'videos',
        component: VideoList,
    },
    {
        path: '/video-categories',
        name: 'video-categories',
        component: VideoCategoryList,
    },
    {
        path: '/setting-page',
        name: 'setting-page',
        component: SettingPage,
    },

    {
        path: '/menu',
        name: 'menu',
        component: AllMenu,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {

    const authStore = useAuthStore();

    if (!authStore.isAuthenticated && to.name!== 'Login') {
        next({ path: '/login' });
    } else if(authStore.isAuthenticated && to.name === 'Login'){
        next({ path: '/dashboard' });
    } else {
        next();
    }
});

export default router;