
<script setup>
import {onMounted, ref} from 'vue';
import apiCaller from "@/apiCalls/apiCaller";
import { useAuthStore } from "@/store/store";
import router from "@/router/router";

const loginData = ref({
  email: '',
  password: '',
});

const authStore = useAuthStore();

onMounted(() => {
  document.title = 'Login';
});

const login = async () => {
   try {
    const response = await apiCaller.post('login', {
      email: loginData.value.email,
      password: loginData.value.password,
    });

    if(response.data.status==true){
      authStore.setToken(response.data.data.authorization.token);
      authStore.setUser(response.data.data.user);
      router.push('/dashboard');
    }
  } catch (error) {
    console.log(error);
  }
};

</script>

<template>
    <div class="main-wrapper">
      <div class="page-wrapper full-page">
        <div class="page-content d-flex align-items-center justify-content-center">

            <div class="card">
                <div class="auth-form-wrapper py-5">
                  <a href="#" class="noble-ui-logo d-block mb-2">
                    <img src="@/assets/images/wenowherelogo.webp" alt="logo" class="logo-style">
                  </a>
                  <h5 class="text-muted fw-normal mb-4">{{ $t('Log In To Your Account') }}</h5>
                  <form id="adminLoginForm" class="forms-sample" @submit.prevent="login">
                    <div class="mb-3">
                      <input type="email" name="email" :placeholder="$t('Email Address')" class="form-control" id="user" required v-model="loginData.email">
                    </div>
                    <div class="mb-3">
                      <input type="password" name="password" :placeholder="$t('Password')" class="form-control" id="userPassword" required v-model="loginData.password">
                    </div>
                    <div>
                      <button id="loginBtn" class="btn btn-primary me-2 mb-2 mb-md-0 text-white" type="submit">
                        {{ $t('Login') }}
                      </button>
                    </div>
                  </form>
                </div>
            </div>

        </div>
      </div>
    </div>
</template>

<style scoped>
.auth-form-wrapper {
  padding: 0px 50px;
  min-width: 500px;
}

@media only screen and (max-width: 900px) {
  .auth-form-wrapper {
    padding: 0px 10px;
    min-width: unset;
  }
}
.logo-style {
 width: 140px;
}
</style>