<template>
  <CategoryList :type="type" />
</template>

<script setup>
import CategoryList from "@/components/common/lists/CategoryList.vue";
import {ref} from "vue";

const type = ref('cases');

</script>