import {createPinia, defineStore} from 'pinia';

export const pinia = createPinia();

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: JSON.parse(localStorage.getItem('ycc_admin_user')) || null,
        token: localStorage.getItem('ycc_admin_token') || null,
    }),
    actions: {
        setUser(user) {
            this.user = user;
            localStorage.setItem('ycc_admin_user', JSON.stringify(user));
        },
        setToken(token) {
            this.token = token;
            localStorage.setItem('ycc_admin_token', token);
        },

        clearUserAndToken() {
            this.user = null;
            this.token = null;
            localStorage.removeItem('ycc_admin_token');
            localStorage.removeItem('ycc_admin_user');

        },
    },

    getters: {
        getUser(state) {
            return state.user;
        },

        getToken(state) {
            return state.token;
        },

        isAuthenticated(state) {
            return state.token!== null;
        }
    }
});

