<template>

  <el-dialog
      :title="$t(title)"
      v-model="modalVisible"
      width="80%"
      :before-close="handleReset"
      align-center
  >
    <el-form :model="formData" ref="formRef" :rules="formRules" label-position="left" label-width="200px"
    style="max-height: 60vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">

      <el-form-item :label="$t('Title')+':'" prop="title" :error="getError('title')">
        <el-input type="text"
                  v-model="formData.title" :placeholder="$t('Title')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Excerpt')+':'" prop="excerpt" :error="getError('excerpt')">
        <el-input type="text"
                  v-model="formData.excerpt" :placeholder="$t('Excerpt')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Url')+':'" prop="title" :error="getError('url')">
        <el-input type="text"
                  v-model="formData.url" :placeholder="$t('Url')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Cover Image')" prop="cover_image" :error="getError('cover_image')">
        <input
            type="file"
            name="fields[assetsFieldHandle][]"
            id="assetsFieldHandle"
            @change="handleSuccessfulUpload"
            ref="file"
            accept="image/*"
        />
        <div v-if="formData.cover_image">
          <img :src="imageUrl" :alt="$t('Cover Image')" style="max-width: 50%; max-height: 100px; margin-top: 10px;">
        </div>
      </el-form-item>

      <el-form-item v-if="props.type.toLowerCase()!=='sliders'" :label="$t('Category')" prop="category_id" :error="getError('category_id')">
        <el-select v-model="formData.category_id" :placeholder="$t('Select Category')" style="width: 100%;">
          <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.title"
              :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Created At')+':'" prop="created_at" :error="getError('created_at')">
        <el-date-picker
            v-model="formData.created_at"
            type="Date"
            :placeholder="$t('Pick a day')"
            size="default"
            style="width: 100%;"
        />
      </el-form-item>

      <el-form-item v-if="formErrors.content" :error="formErrors.content[0]">
        <template v-slot:error>
          <span style="color: red;">{{ formErrors.content[0] }}</span>
        </template>
      </el-form-item>

      <QuillEditor theme="snow"
          toolbar="full"
          :modules="quillModules"
          v-model:content="formData.content"
          ref='quillRef' :style="{ minHeight: '200px' }"
          :placeholder="$t('Description')" required
          content-type="html"
      />

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleReset">{{ $t('Cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit()" :disabled="isSubmit">
          {{ $t('Confirm') }}
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script setup>

import {
  ref,
  onMounted,
  defineEmits,
  defineProps } from 'vue';
import apiCaller from "@/apiCalls/apiCaller";
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {quillModules} from "@/modules/quillModules";
import {useI18n} from 'vue-i18n';

const {t} = useI18n();
let isSubmit = ref(false);
let formErrors = ref({});

const props = defineProps({
  content: Object,
  type: String
});

let title = ref('');

let modalVisible = ref();


const formData = ref({
  type: '',
  title: '',
  url: '',
  excerpt: '',
  cover_image: '',
  category_id: '',
  content: '',
  created_at: ''
});

let categories = ref([]);

let categoryParams = ref({
  type: '',
  currentPage: -1,
  search: ''
});

let imageUrl = ref();
const validateImageType = (rule, file, callback) => {
  if (typeof file === 'string') {
    callback();
  }
  else if (!file) {
    callback(new Error('Please upload an image'));
  } else {
    const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'];
    if (!allowedTypes.includes(file.type)) {
      callback(new Error('Invalid image type. Please upload a JPG, PNG, or BMP.'));
    } else {
      callback();
    }
  }
};


const formRules = ref({
  title: [
    { required: true, message: t('Please Enter The Title'), trigger: 'blur' }
  ],

  url: [
    { required: true, message: t('Please Enter The Url'), trigger: 'blur' }
  ],

  excerpt: [
    { required: true, message: t('Please Enter The Excerpt'), trigger: 'blur' }
  ],
  cover_image: [
    { required: false, message: t('Please Upload An Image'), trigger: 'change' },
    { validator: validateImageType, trigger: 'change' },
  ],
  category_id: [
    { required: props.type.toLowerCase()=='sliders'? false:true, message: t('Please Enter The Category'), trigger: 'blur' }
  ],

  content: [
    { required: true, message: t('Please Enter The Content'), trigger: 'blur' }
  ],

  created_at: [
    { required: true, message: t('Please Select A Date'), trigger: 'blur' }
  ],
});

const formRef = ref(null);
const quillRef = ref(null);

onMounted(async ()=>{
  categoryParams.value.type = props.type.toLowerCase();
  title.value = 'Edit '+ props.type;

  modalVisible.value = true;
  if(props.type.toLowerCase() !== 'sliders') {
    const res = await apiCaller.get('categories', categoryParams.value);
    categories.value = res.data.data;
  }

  formData.value.type = props.type.toLowerCase();
  formData.value.title = props.content.title;
  formData.value.excerpt = props.content.excerpt;
  formData.value.cover_image = props.content.image_url;
  formData.value.category_id = props.content.category_id;
  formData.value.content = props.content.content;
  formData.value.created_at = props.content.created_at
  formData.value.url = props.content.url;

  imageUrl.value = formData.value.cover_image;

});

const emits = defineEmits(['cancel:showEditModal', 'saved:showEditModal'])

const handleSubmit = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      isSubmit.value = true;

      const data = new FormData();
      data.append('type', formData.value.type);
      data.append('cover_image', formData.value.cover_image);
      data.append('title', formData.value.title);
      data.append('category_id', formData.value.category_id);
      data.append('content', formData.value.content);
      data.append('excerpt', formData.value.excerpt);
      data.append('url', formData.value.url);

      data.append('created_at', new Date(formData.value.created_at).toISOString().split('T')[0]);
      data.append('_method', 'PUT')

      const url = 'contents/' + props.content.id;

      apiCaller.post(url, data)
          .then(() => {
            emits('saved:showEditModal');
            isSubmit.value = false;
          }).catch(error => {
            formErrors.value = error.response.data.data;
            isSubmit.value = false;
          });
    }
  });
};

const handleReset = () => {
  formRef.value.resetFields();
  modalVisible.value = false;
  emits('cancel:showEditModal');
};


function handleSuccessfulUpload(e) {
  if (!e.target.files[0].type.startsWith('image/')) {

    alert('Please select only image files.');
    return;
  }

  formData.value.cover_image = e.target.files[0];
  let reader = new FileReader();
  reader.readAsDataURL(e.target.files[0]);
  reader.onloadend = event => {
    imageUrl.value = event.target.result;
  };
}

function getError(key)
{
  if(formErrors.value[key])
  {
    return formErrors.value[key][0];
  }
}

</script>