<template>

  <el-dialog
      v-model="showModalVisible"
      :title="props.content.title"
      width="60%"
      :before-close="handleReset"
  >
    <div style="max-height: 40vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">
      <img :src="props.content.image_url" style="width: 50%; height: 50%; display: block; margin: auto;">
    </div>
    <template #footer>
      <div style="display:flex; justify-content:space-between; align-items:center">
        <el-button type="danger" plain :icon="Delete" @click="handleDelete(props.content)"/>
        <el-button type="primary" plain :icon="EditPen" @click="handleEdit(record)"/>
      </div>
    </template>
  </el-dialog>

</template>

<style scoped>
.content-container {
  text-align: left;
}

.left-align {
  text-align: left;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}
</style>

<script setup>

import {ref, onMounted, defineEmits, defineProps} from 'vue';
import {
  EditPen, Delete
} from '@element-plus/icons-vue'


const props = defineProps({
  content: Object
});

let showModalVisible = ref(false);
const emits = defineEmits(['cancel:viewModal', 'handleEdit', 'handleDelete'])


onMounted(async ()=>{
  showModalVisible.value = true;
});

const handleReset = () => {
  showModalVisible.value = false;
  emits('cancel:showViewModal');
};

const handleEdit = () => {
  showModalVisible.value = false;
  emits('handleEdit');
}

const handleDelete = (record) =>{
  emits('handleDelete', record);
}
</script>