<template>

  <el-dialog v-model="showModalVisible" width="60%" :before-close="handleReset"  :title="props.content.title">

    <div style="max-height: 40vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">
        <div class="sticky-dialog-container">

          <div class="content-body">
            <img :src="props.content.image_url" style="width: 100%; height: 100%; display: block; margin: auto;">

            <div>
              <a-row :style="{ marginTop: '20px' }">
                <a-col><h5>{{ $t('Date') }} : </h5></a-col>
                <a-col>{{ props.content.created_at }}</a-col>

                <a-col v-if="props.content.category_id !== null" :style="{ marginLeft: '20px' }"><h5>{{ $t('Category') }} :</h5></a-col>
                <a-col v-if="props.content.category_id !== null">{{ props.content.category_name }}</a-col>
              </a-row>
            </div>

            <h4 style="text-align: center;">{{ props.content.title }}</h4>

            <div class="content-container">
              <div v-html="props.content.content"></div>
            </div>
          </div>

        </div>
    </div>

    <template #footer>
      <div style="display:flex; justify-content:space-between; align-items:center">
        <el-button type="danger" plain :icon="Delete" @click="handleDelete(props.content)"/>
        <el-button type="primary" plain :icon="EditPen" @click="handleEdit(record)"/>
      </div>
    </template>

  </el-dialog>
</template>

<style scoped>
.sticky-dialog-container {
  position: relative;
}

.card-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  text-align: left;
}

.content-body {
  max-height: calc(100vh - 150px);
}

.content-container {
  text-align: left;
}

.left-align {
  text-align: left;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
}
</style>

<script setup>

import {ref, onMounted, defineEmits, defineProps} from 'vue';
import {
  EditPen, Delete
} from '@element-plus/icons-vue'


const props = defineProps({
  content: Object,
});

let showModalVisible = ref(false);
const emits = defineEmits(['cancel:viewModal', 'handleEdit', 'handleDelete'])


onMounted(async ()=>{
  showModalVisible.value = true;

});

const handleReset = () => {

  showModalVisible.value = false;

  emits('cancel:showViewModal');
};

const handleEdit = () => {
  showModalVisible.value = false;
  emits('handleEdit');
}

const handleDelete = (record) =>{
  emits('handleDelete', record);
}
</script>