<template>

  <UserListComponent :role="type"/>

</template>

<script setup>

import UserListComponent from "@/components/common/lists/UserListComponent.vue";
import {ref} from "vue";

const type = ref('admin');

</script>