<template>

  <el-dialog
      :title="$t('Create New')"
      v-model="modalVisible"
      width="80%"
      :before-close="handleReset"
      align-center
  >
    <el-form :model="formData" ref="formRef" :rules="formRules" label-position="left" label-width="200px"
    style="max-height: 60vh; overflow:auto; scrollbar-width:thin; padding-right:5px;">

      <el-form-item :label="$t('Cover Image')" prop="profile_image" :error="getError('profile_image')">
        <input
            type="file"
            name="fields[assetsFieldHandle][]"
            id="assetsFieldHandle"
            @change="uploadCoverImage"
            ref="file"
            accept="image/*"
        />

        <div v-if="formData.profile_image">
          <img :src="imageUrl" :alt="$t('Cover Image')" style="max-width: 50%; max-height: 100px; margin-top: 10px;">
        </div>

      </el-form-item>

      <el-form-item :label="$t('First Name')+':'" prop="first_name" :error="getError('first_name')">
        <el-input type="text"
                  v-model="formData.first_name" :placeholder="$t('First Name')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Last Name')+':'" prop="last_name" :error="getError('last_name')">
        <el-input type="text"
                  v-model="formData.last_name" :placeholder="$t('Last Name')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('User Name')" prop="user_name" :error="getError('user_name')">
        <el-input type="text" @input="usernameChanged"
                  v-model="formData.user_name" :placeholder="$t('User Name')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Url')+':'" prop="url" :error="getError('url')">
        <el-input type="text"
                  v-model="formData.url" :placeholder="$t('Url')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Email')" prop="email" :error="getError('email')">
        <el-input type="text"
                  v-model="formData.email" :placeholder="$t('Email')" aria-required="true">
        </el-input>
        <el-form-item v-if="formErrors.email" :error="formErrors.email[0]">
          <template v-slot:error>{{ formErrors.email[0] }}</template>
        </el-form-item>
      </el-form-item>

      <el-form-item :label="$t('Mobile')" prop="mobile" :error="getError('mobile')">
        <el-input type="text"
                  :pattern="allowedNumber"
                  v-model="formData.mobile" :placeholder="$t('Mobile')" aria-required="true">
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('Created At')+':'" prop="created_at" :error="getError('created_at')">
        <el-date-picker
            v-model="formData.created_at"
            type="Date"
            :placeholder="$t('Pick a day')"
            size="default"
            style="width: 100%;"
        />
      </el-form-item>

      <el-form-item :label="$t('Password')" prop="password" :error="getError('password')">
        <el-input type="password"
                  v-model="formData.password" :placeholder="$t('Password')" aria-required="true">
        </el-input>
      </el-form-item>


    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleReset">{{ $t('Cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit()" :disabled="isSubmit">
          {{ $t('Confirm') }}
        </el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script setup>

import {ref, onMounted, defineEmits, defineProps} from 'vue';
import apiCaller from "@/apiCalls/apiCaller";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {imageUploader} from "@/apiCalls/imageUploader";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
let isSubmit = ref(false);
const props = defineProps({
  role: String,
});

const allowedNumber =  ref(/^[0-9]+(?:\+[0-9]*)?$/);
let imageUrl = ref();
let modalVisible = ref();


const formData = ref({
  role: '',
  first_name: '',
  last_name: '',
  user_name: '',
  url: '',
  email: '',
  mobile: '',
  password: '',
  profile_image: null,
  created_at: ''
});

let formErrors = ref({});

const formRules = ref({
  profile_image: [
    {required: false, message: t('Please Upload An Image'), trigger: 'blur'}
  ],

  url: [
    {required: true, message: t('Please Enter The Url'), trigger: 'blur'}
  ],

  first_name: [
    {required: true, message: t('Please Enter The First Name'), trigger: 'blur'}
  ],

  last_name: [
    {required: true, message: t('Please Enter The Last Name'), trigger: 'blur'}
  ],

  email: [
    {required: true, message: t('Please Enter The Email'), trigger: 'blur'}
  ],

  mobile: [
    {required: true, message: t('Please Enter The Mobile Number'), trigger: 'blur'}
  ],

  user_name: [
    {required: true, message: t('Please Enter The User Name'), trigger: 'blur'}
  ],

  password: [
    {required: true, message: t('Please Enter Password'), trigger: 'blur'}
  ],

  created_at: [
    {required: true, message: t('Please Select A Date'), trigger: 'blur'}
  ],

});

const formRef = ref(null);

onMounted(async () => {
  modalVisible.value = true;
  formData.value.role = props.role;
});

const emits = defineEmits(['cancel:showModal', 'saved:showModal'])

const handleSubmit = () => {

  formRef.value.validate((valid) => {
    if (valid) {
      isSubmit.value = true;
      formData.value.role = props.role;
      formData.value.created_at = new Date(formData.value.created_at).toISOString().split('T')[0];
      apiCaller.post('users', formData.value)
          .then(() => {
            emits('saved:showModal');
            isSubmit.value = false;
          }).catch(error => {
            formErrors.value = error.response.data.data;
            isSubmit.value = false;
          });
    }
  });
};

const handleReset = () => {
  formRef.value.resetFields();
  modalVisible.value = false;
  emits('cancel:showModal');
};

async function uploadCoverImage(e)
{
  if (!e.target.files[0].type.startsWith('image/')) {
    alert('Please select only image files.');
    return;
  }

  let images = [];
  images = e.target.files;

  const res = await imageUploader.handleFilelUpload(images);
  imageUrl.value = res.data.data.url[0];
  formData.value.profile_image = res.data.data.path[0];
}

function getError(key)
{
  if(formErrors.value[key])
  {
    return formErrors.value[key][0];
  }
}

const usernameChanged = () => {
  formData.value.url = formData.value.user_name;
}

</script>