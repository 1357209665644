<script setup>
import {
    BookOutlined,
    PieChartOutlined,
    LayoutOutlined,
    NumberOutlined,
    WindowsOutlined,
    UserAddOutlined,
    TagOutlined,
    TeamOutlined,
    StarOutlined,
    MailOutlined,
    UserOutlined,
    SafetyCertificateOutlined,
    PlayCircleOutlined,
    MenuOutlined
} from '@ant-design/icons-vue';
import { ref } from 'vue';
import { RouterLink, useRoute } from 'vue-router';

const route = useRoute();
const collapsed = ref(false);

if (window.innerWidth <= 992) {
  collapsed.value = true;
}

window.addEventListener('resize', () => {
  if (window.innerWidth <= 992) {
    collapsed.value = true;
  } else {
    collapsed.value = false;
  }
});

const pathKeyMap = {
  '/dashboard' : '1',
  '/pages' : '2',
  '/page-categories' : '3',
  '/sliders' : '4',
  '/products' : '5',
  '/product-categories' : '6',
  '/product-reviews' : '7',
  '/posts' : '8',
  '/post-categories' : '9',
  '/post-reviews' : '10',
  '/cases' : '11',
  '/case-categories' : '12',
  '/case-reviews' : '13',
  '/jobs' : '14',
  '/job-categories' : '15',
  '/job-reviews' : '16',
  '/users' : '17',
  '/user-reviews' : '18',
  '/inquiries' : '19',
  '/admins' : '20',
  '/logos' : '21',
  '/logo-categories' : '22',
  '/menu' : '25',
  '/videos' : '23',
  '/video-categories' : '24',
};

const openKeyPrefixs = {
  '/page' : 'sub1',
  '/product' : 'sub2',
  '/post' : 'sub3',
  '/case' : 'sub4',
  '/job' : 'sub5',
  '/logo' : 'sub6',
  '/video' : 'sub7',
  '/sliders' : 'sub8'
};

function getSelectedKeys()
{
  const path = route.path;

  return [pathKeyMap[path]];
}

function getopenKeys()
{
  const path = route.path;

  for(const [key , value] of Object.entries(openKeyPrefixs))
  {
    if(path.startsWith(key))
    {
      return [value];
    }
  }
}

const selectedKeys = ref(getSelectedKeys());
const openKeys = ref(getopenKeys());

</script>
<template>
  <a-layout-sider class="ant-layout-sider" v-model:collapsed="collapsed" collapsible>
    <div class="logo">
      <img src="@/assets/images/wenowherelogo.webp" alt="Logo" class="sidebar-logo-style">
    </div>
    <a-menu v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" theme="dark" mode="inline" style="height: 90vh; overflow-y: auto;">
      <a-menu-item disabled>
        <span class="non-clickable">
          {{ $t('Overview') }}
        </span>
      </a-menu-item>

      <a-menu-item key="1">
        <PieChartOutlined />
        <span>
          <router-link to="/dashboard">{{ $t('Dashboard') }}</router-link>
        </span>
      </a-menu-item>


      <a-menu-item key="22">
        <MenuOutlined />
        <span>
          <router-link to="/menu">{{ $t('Menu') }}</router-link>
        </span>
      </a-menu-item>


      <a-menu-item disabled>
        <span class="non-clickable">
          {{ $t('Contents') }}
        </span>
      </a-menu-item>

      <a-sub-menu key="sub1">
        <template #title>
          <span style="display:flex; align-items:center;">
            <LayoutOutlined />
            <span>{{ $t('Pages') }}</span>
          </span>
        </template>
        <a-menu-item key="2">
          <span>
            <NumberOutlined />
          <span>
            <RouterLink to="/pages">{{ $t('List') }}</RouterLink>
          </span>
          </span>
        </a-menu-item>
        <a-menu-item key="3">
          <span>
          <NumberOutlined />
            <span>
                <RouterLink to="/page-categories">{{ $t('Categories') }}</RouterLink>

            </span>
          </span>

        </a-menu-item>

        <a-menu-item key="4">
          <span>
          <NumberOutlined />
            <span>
                <RouterLink to="/sliders">{{ $t('Sliders') }}</RouterLink>

            </span>
          </span>

        </a-menu-item>

      </a-sub-menu>

      <a-sub-menu key="sub2">
        <template #title>
          <span style="display:flex; align-items:center;">
            <TagOutlined />
            <span>{{ $t('Products') }}</span>
          </span>
        </template>
        <a-menu-item key="5">
          <span>
            <NumberOutlined />
          <span>
            <RouterLink to="/products">{{ $t('List') }}</RouterLink>
          </span>
          </span>
        </a-menu-item>
        <a-menu-item key="6">
          <span>
          <NumberOutlined />
            <span>
                <RouterLink to="/product-categories">{{ $t('Categories') }}</RouterLink>

            </span>
          </span>

        </a-menu-item>

        <a-menu-item key="7">
          <span>
          <NumberOutlined />
            <span>
                <RouterLink to="/product-reviews">{{ $t('Reviews') }}</RouterLink>

            </span>
          </span>

        </a-menu-item>

      </a-sub-menu>

      <a-sub-menu key="sub3">
        <template #title>
          <span style="display:flex; align-items:center;">
             <WindowsOutlined />
            <span>{{ $t('Posts') }}</span>
          </span>
        </template>
        <a-menu-item key="8">
          <span>
            <NumberOutlined />
            <span>
              <RouterLink to="/posts">{{ $t('List') }}</RouterLink>
            </span>
          </span>

        </a-menu-item>
        <a-menu-item key="9">
          <span>
            <NumberOutlined />
            <span>
              <RouterLink to="/post-categories">{{ $t('Categories') }}</RouterLink>
            </span>
          </span>
        </a-menu-item>
        <a-menu-item key="10">
          <span>
          <NumberOutlined />
            <span>
                <RouterLink to="/post-reviews">{{ $t('Reviews') }}</RouterLink>

            </span>
          </span>

        </a-menu-item>

      </a-sub-menu>
      <a-sub-menu key="sub4">
        <template #title>
          <span style="display:flex; align-items:center;">
            <BookOutlined />
            <span>{{ $t('Cases') }}</span>
          </span>
        </template>
        <a-menu-item key="11">
          <span><NumberOutlined />
            <span><RouterLink to="/cases">{{ $t('List') }}</RouterLink>
          </span>
          </span>
        </a-menu-item>
        <a-menu-item key="12">
          <span>
            <NumberOutlined />
            <span>
              <RouterLink to="/case-categories">{{ $t('Categories') }}</RouterLink>
            </span>

          </span>

        </a-menu-item>
        <a-menu-item key="13">
          <span>
          <NumberOutlined />
            <span>
                <RouterLink to="/case-reviews">{{ $t('Reviews') }}</RouterLink>

            </span>
          </span>

        </a-menu-item>


      </a-sub-menu>

      <a-sub-menu key="sub5">
        <template #title>
          <span style="display:flex; align-items:center;">
            <UserAddOutlined />
            <span> {{ $t('Jobs') }}</span>
          </span>
        </template>
        <a-menu-item key="14">
          <span>
          <NumberOutlined />
          <span><RouterLink to="/jobs">{{ $t('List') }}</RouterLink></span>
          </span>
        </a-menu-item>
        <a-menu-item key="15">
          <span>
            <NumberOutlined />
            <span><RouterLink to="/job-categories">{{ $t('Categories') }}</RouterLink></span>
          </span>

        </a-menu-item>
        <a-menu-item key="16">
          <span>
            <NumberOutlined />
            <span><RouterLink to="/job-reviews">{{ $t('Reviews') }}</RouterLink></span>
          </span>

        </a-menu-item>

      </a-sub-menu>
      
      <a-sub-menu key="sub6">
        <template #title>
          <span style="display:flex; align-items:center;">
            <SafetyCertificateOutlined />
            <span>{{ $t('Logos') }}</span>
          </span>
        </template>
        <a-menu-item key="21">
          <span>
          <NumberOutlined />
          <span><RouterLink to="/logos">{{ $t('List') }}</RouterLink></span>
          </span>
        </a-menu-item>
        <a-menu-item key="22">
          <span>
            <NumberOutlined />
            <span><RouterLink to="/logo-categories">{{ $t('Categories') }}</RouterLink></span>
          </span>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu key="sub7">
        <template #title>
          <span style="display:flex; align-items:center;">
            <PlayCircleOutlined />
            <span>{{ $t('Videos') }}</span>
          </span>
        </template>
        <a-menu-item key="23">
          <span>
          <NumberOutlined />
          <span><RouterLink to="/videos">{{ $t('List') }}</RouterLink></span>
          </span>
        </a-menu-item>
        <a-menu-item key="24">
          <span>
            <NumberOutlined />
            <span><RouterLink to="/video-categories">{{ $t('Categories') }}</RouterLink></span>
          </span>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item disabled>
        <span class="non-clickable">
          {{ $t('Clients') }}
        </span>
      </a-menu-item>
      <a-menu-item key="17">
        <TeamOutlined/>
        <span>
          <router-link to="/users">{{ $t('Users') }}</router-link>
        </span>
      </a-menu-item>

      <a-menu-item key="18">
        <StarOutlined />
        <span>
          <router-link to="/user-reviews">{{ $t('Reviews') }}</router-link>
        </span>
      </a-menu-item>

      <a-menu-item key="19">
        <MailOutlined />
        <span>
          <router-link to="/inquiries">{{ $t('Inquiries') }}</router-link>
        </span>
      </a-menu-item>

      <a-menu-item disabled>
        <span class="non-clickable">
          {{ $t('Settings') }}
        </span>
      </a-menu-item>
      <a-menu-item key="20">
        <UserOutlined />
        <span>
          <router-link to="/admins">{{ $t('Admins') }}</router-link>
        </span>
      </a-menu-item>


    </a-menu>
  </a-layout-sider>
</template>
<style>
.ant-layout-sider-trigger {
  width: 240px !important;
}

.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  width: 140px !important;
}

.ant-layout-sider {
  box-shadow: 2px 0px 15px #00000055;
  background: #fff !important;
}

.ant-layout-sider .ant-menu,
.ant-menu-submenu-popup ul {
  background: #fff !important;
  color: #181818;
  text-align: left;
}

.ant-layout-sider .ant-menu-item-selected,
.ant-layout-sider .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-item:active {
  background: #ddd;
  text-align: left;
}

.ant-layout-sider .ant-menu-item:not(.ant-menu-item-selected):hover a {
  color: #000;
}

.ant-menu-title-content {
 display: flex;
 align-items: center;
}

.ant-layout-sider .ant-menu-submenu-selected .ant-menu-title-content {
  color: #000;
  text-align: left;

}

.ant-layout-sider .ant-menu-submenu:hover .ant-menu-title-content {
  color: #000;
  text-align: left;
}

.ant-layout-sider .ant-menu-submenu-selected {
  color: #1677ff;
  text-align: left;
}

.ant-layout-sider .ant-menu-item-selected .ant-menu-title-content {
  color: #1677ff !important;
}

.ant-layout-sider .ant-menu-submenu-selected .ant-menu-submenu-arrow::before{
  text-align: left;
}
.ant-layout-sider .ant-menu-submenu-selected .ant-menu-submenu-arrow::after {
  color: #000;
  text-align: left;

}

.ant-layout-sider .ant-menu-submenu:hover .ant-menu-submenu-arrow::before,
.ant-layout-sider .ant-menu-submenu:hover .ant-menu-submenu-arrow::after {
  color: #000;
}

.ant-layout-sider .ant-layout-sider-trigger {
  background: #ddd;
}

.ant-layout-sider .ant-layout-sider-trigger .anticon-right svg,
.ant-layout-sider .ant-layout-sider-trigger .anticon-left svg,
.ant-menu-item:hover .anticon-home svg,
.ant-menu-item-selected .anticon-home svg {
  color: #000 !important;
}

.ant-menu-submenu-popup ul .ant-menu-item:not(.ant-menu-item-selected):hover .ant-menu-title-content {
  color: #000;
}

.ant-menu-submenu-popup ul .ant-menu-item-selected {
  color: #1677ff;
  background: #ddd;
}
</style>
<style scoped>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.non-clickable {
  color: #7987a1;
  font-weight: bold;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.logo img {
  width: 100px;
  height: 30px;
}

.ant-layout .ant-layout-sider {
  width: 240px !important;
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  z-index: 4 !important;
}

.ant-layout .ant-layout-sider-collapsed {
  width: 140px !important;
  flex: 0 0 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
}

.ant-menu-submenu-title {
  padding-left: 16px !important;
}

.ant-layout-sider-collapsed img {
  width: 70px !important;
  height: 20px !important;
}

.ant-layout-sider-collapsed .ant-menu-submenu-title {
  padding-left: 24px !important;
  text-align: left;

}

.site-layout .site-layout-background {
  background: #fff;
}
.sidebar-logo-style {
 width: 100px;
}
</style>